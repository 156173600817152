import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import basicStyle from './Basic.scss';

const FormNumber = ({ size, disabled, className, style, value, prefix, suffix, error, change, require = false }) => {
  const classNames = () => {
    const requireClass = `${value}` === '' && require;
    return [basicStyle.glText, className, requireClass ? basicStyle.requireInput : ''];
  };
  const inValid = error !== null && error !== undefined && error !== '';
  return (
    <InputGroup size={size}>
      {prefix ? <InputGroup.Text>{prefix}</InputGroup.Text> : <></>}
      <Form.Control
        type="number"
        className={classNames().filter(Boolean).join(' ')}
        disabled={disabled}
        value={value}
        size={size}
        style={style}
        onChange={change}
        isInvalid={inValid}
      />
      {suffix ? <InputGroup.Text>{suffix}</InputGroup.Text> : <></>}
      {inValid ? <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback> : <></>}
    </InputGroup>
  );
};

FormNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  change: PropTypes.func,
  prefix: PropTypes.string,
  suffix: PropTypes.node,
  error: PropTypes.string,
};

FormNumber.defaultProps = {
  size: 'sm',
  disabled: false,
  change: () => {},
  error: null,
};

export default FormNumber;
