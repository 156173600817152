import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { getPurchaseTexts, getReport, addReport, updateReport, deleteReport } from '@/api';
import { FNum, FDate, FSel, FRadio, Btn, FNote, FTxt } from '@/components/basic';
import { FItemGroup, Section, Toast } from '@/components/shared';
import style from '@/pages/report/Report.scss';
import ConfirmModal from '@/components/page-parts/report-edit/ConfirmModal';
import { Report, ReportProgress } from '@/entity/Report';
import { Validator, Rule } from '@/util/validate';
import { isEmpty } from '@/util/util';

const GoalReportForm = ({
  studentId,
  isAddMode,
  targetReportId,
  prevReportId,
  onBack,
  onDraftStored,
  onSubmitted,
  showExamModalFunc,
  showRecordModalFunc,
}) => {
  const [report, setReport] = useState(new Report());
  const [errors, setErrors] = useState({});
  const [beforeReport, setBeforeReport] = useState(new Report());
  const [purchaseTexts, setPurchaseTexts] = useState([]);
  const [confirmModal, setConfirmModal] = useState({
    show: false,
    title: '',
    content: '',
    buttonTitle: '',
    handler: () => {},
  });
  const hours = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22].map((r) => {
    return { value: r, label: `${r}時` };
  });
  const minutes = [0, 1, 2, 3, 4, 5].map((r) => {
    return { value: r * 10, label: `${r * 10}分` };
  });
  const pointRateSelection = window.env.report.pointRateSelection;
  const pointsSelection = window.env.report.pointSelection;

  // なんか100ミリ秒遅らさないとページトップにいかない。
  const toTopPos = () => setTimeout(() => window.scrollTo(0, 0), 100);

  const handleShowConfirm = (title, content, buttonTitle, handler = () => {}) =>
    setConfirmModal({
      ...confirmModal,
      show: true,
      title,
      content,
      buttonTitle,
      handler,
    });
  const handleCloseConfirm = () =>
    setConfirmModal({ ...confirmModal, show: false, title: '', content: '', handler: () => {} });
  const setAndAdjustReport = (reportData) => {
    if (!reportData.isTurnIn) {
      reportData.mergeQuestionnaires();
    }
    setReport(reportData);
  };
  const addNewText = () => {
    report.reportProgress.push(new ReportProgress());
    setReport({
      ...report,
      reportProgress: report.reportProgress,
    });
  };
  const removeText = (index) => {
    if (report.id) {
      const rmId = report.reportProgress[index].id;
      report.removeTexts.push({ id: rmId });
    }
    setReport({
      ...report,
      reportProgress: report.reportProgress.filter((_, i) => i !== index),
      removeTexts: report.removeTexts,
    });
  };
  const validate = () => {
    const validate = new Validator(report);
    validate.exec('date', '指導日', [Rule.require()]);
    validate.exec('hour', '指導日(時)', [Rule.require()]);
    validate.exec('minute', '指導日(分)', [Rule.require()]);
    validate.exec('time', '指導時間', [Rule.require()]);
    report.reportProgress.forEach((_, i) => {
      if (purchaseTexts.length > 0) {
        validate.exec(`reportProgress[${i}].code`, 'テキスト', [Rule.require('select')]);
      } else {
        validate.exec(`reportProgress[${i}].textName`, 'テキスト', [Rule.require()]);
      }
      if (window.env.report.page) {
        validate.exec(`reportProgress[${i}].page`, 'ページ', [Rule.require(), Rule.num(), Rule.numRange(0, 999)]);
        validate.exec(`reportProgress[${i}].maxPage`, '最大ページ', [
          Rule.require(),
          Rule.num(),
          Rule.numRange(0, 999),
          Rule.custom((value) => {
            const compareValue = report.reportProgress[i].page;
            if (isEmpty(compareValue) || isEmpty(value)) return false;
            if (parseInt(compareValue) > parseInt(value)) return true;
          }, '最大ページはページより大きな値を入力してください。'),
        ]);
      }
      if (window.env.report.learningUnit) {
        validate.exec(`reportProgress[${i}].learningUnit`, '単元単位', [Rule.require()]);
      }
    });
    validate.exec('fee', '指導料金', [Rule.require(), Rule.num(), Rule.numRange(0, 999999)]);
    validate.exec('travelCost', '交通費', [Rule.require(), Rule.num(), Rule.numRange(0, 999999)]);
    validate.exec('nextDate', '次回指導日', [
      Rule.custom((value) => {
        if (isEmpty(report.date) || isEmpty(value)) return false;
        if (report.date >= value) return true;
      }, '次回指導日は未来日付で入力してください。'),
    ]);
    setErrors(validate.getErrors());
    return validate.inValid();
  };
  const getBeforePage = (row) => {
    const hit = beforeReport.reportProgress.find((r) => row.code === r.code);
    if (!hit) return '';
    return `${'\r\n'}　(前回は${hit.page}まで)`;
  };
  const storeForDraft = async () => {
    const r = new Report({ ...report });
    !report.id ? await addReport(studentId, r) : await updateReport(studentId, report.id, r);
    handleCloseConfirm();
    onDraftStored();
    toTopPos();
    Toast.success('下書き保存しました。');
  };
  const deleteDraft = async () => {
    await deleteReport(studentId, report.id);
    handleCloseConfirm();
    onBack();
    Toast.success('下書きを削除しました。');
  };
  const storeForSubmit = async () => {
    if (validate()) {
      handleCloseConfirm();
      toTopPos();
      Toast.error('入力エラーがあります。');
      return;
    }
    report.isTurnIn = true;
    const r = new Report({ ...report });
    !report.id ? await addReport(studentId, r) : await updateReport(studentId, report.id, r);
    handleCloseConfirm();
    onSubmitted();
    toTopPos();
    Toast.success('ご家庭に指導報告書を提出しました。');
  };

  useEffect(async () => {
    const d = await getPurchaseTexts(studentId);
    setPurchaseTexts(
      d.map((r) => {
        r['textbookName'] = `${r.textName} ${r.gradeName} ${r.subjectName}`;
        return r;
      })
    );
  }, []);

  useEffect(async () => {
    if (isAddMode) {
      const now = new Report();
      let prev = null;
      if (prevReportId) {
        prev = await getReport(studentId, prevReportId);
        setBeforeReport(prevReportId ? prev : new Report());
        now.copyPrevProgress(prev);
      }
      setReport(now);
      return;
    }
    if (studentId === null || targetReportId === null) return;
    const dt = await getReport(studentId, targetReportId);
    setAndAdjustReport(dt);
    setBeforeReport(prevReportId ? await getReport(studentId, prevReportId) : new Report());
  }, [isAddMode, targetReportId, prevReportId]);

  return (
    <Form noValidate style={{ marginTop: '1rem' }}>
      <Section title="指導について">
        <FItemGroup
          labels={[
            { label: '指導日時', size: 5 },
            { label: '開始時間', size: 6 },
          ]}
          full={false}
          className={style.spaceB}
          errors={errors}
          paths={['date', 'hour', 'minute']}
        >
          <Col xs={5} style={{ paddingRight: 0 }}>
            <FDate
              value={report.date}
              change={(e) => setReport({ ...report, date: e.target.value })}
              disabled={report.isApproval}
            />
          </Col>
          <Col xs={3} style={{ paddingRight: 0 }}>
            <FSel
              options={hours}
              value={report.hour}
              change={(e) => setReport({ ...report, hour: e.target.value })}
              disabled={report.isApproval}
            />
          </Col>
          <Col xs={3} style={{ paddingRight: 0 }}>
            <FSel
              options={minutes}
              value={report.minute}
              change={(e) => setReport({ ...report, minute: e.target.value })}
              disabled={report.isApproval}
            />
          </Col>
        </FItemGroup>
        <FItemGroup label="指導時間" full={false} className={style.spaceB} errors={errors} paths={['time']}>
          <Col xs={5}>
            <FNum
              suffix="分"
              value={report.time}
              change={(e) => setReport({ ...report, time: e.target.value })}
              disabled={report.isApproval}
            />
          </Col>
        </FItemGroup>
        {report.reportProgress.map((r, i) => {
          return (
            <React.Fragment key={i.toString()}>
              <FItemGroup
                label={r.id ? `${r.textName} ${r.gradeName} ${r.subjectName} ${getBeforePage(r)}` : '新規テキスト'}
                full={false}
                errors={errors}
                paths={[`reportProgress[${i}].code`]}
              >
                <Row style={{ marginRight: 'none', paddingRight: '0px' }}>
                  {r.id ? (
                    <>
                      {!report.isApproval && (
                        <>
                          <Col xs={10} style={{ marginBottom: '0.5rem', paddingRight: '0px' }}></Col>
                          <Col
                            xs={2}
                            style={{ textAlign: 'right', marginRight: '0px', padding: '0px', marginTop: '-30px' }}
                          >
                            <Btn
                              isCustom
                              outline
                              prefixIcon="faXmark"
                              click={() => removeText(i)}
                              width="30px"
                              height="30px"
                              className={style.xmarkButton}
                            />
                          </Col>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Col xs={10} style={{ paddingRight: '0px' }}>
                        {purchaseTexts.length > 0 && (
                          <>
                            <FSel
                              options={purchaseTexts}
                              labelField="textbookName"
                              valueField="textbookName"
                              value={`${r.textName} ${r.gradeName} ${r.subjectName}`}
                              change={(e, row) => {
                                report.reportProgress[i].code = e.target.value;
                                report.reportProgress[i].textName = row.textName;
                                report.reportProgress[i].gradeName = row.gradeName;
                                report.reportProgress[i].subjectName = row.subjectName;
                                setReport({ ...report, reportProgress: report.reportProgress });
                              }}
                              disabled={report.isApproval}
                            />
                          </>
                        )}
                        {purchaseTexts.length === 0 && (
                          <>
                            <FTxt
                              change={(e, row) => {
                                report.reportProgress[i].code = '';
                                report.reportProgress[i].textName = e.target.value;
                                report.reportProgress[i].gradeName = '';
                                report.reportProgress[i].subjectName = '';
                                setReport({ ...report, reportProgress: report.reportProgress });
                              }}
                              value={`${r.textName}`}
                              disabled={report.isApproval}
                            ></FTxt>
                          </>
                        )}
                      </Col>
                      <Col xs={2} style={{ textAlign: 'right', marginRight: '0px', padding: '0px' }}>
                        <Btn
                          isCustom
                          outline
                          prefixIcon="faXmark"
                          click={() => removeText(i)}
                          width="30px"
                          height="30px"
                          className={style.xmarkButton}
                          disabled={report.isApproval}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </FItemGroup>
              {window.env.report.page ? (
                <FItemGroup
                  label="ページ"
                  full={false}
                  style={{ marginLeft: '1rem' }}
                  errors={errors}
                  paths={[`reportProgress[${i}].page`, `reportProgress[${i}].maxPage`]}
                >
                  <Row>
                    <Col xs={5}>
                      <FNum
                        suffix="Ｐ"
                        value={r.page}
                        change={(e) => {
                          report.reportProgress[i].page = e.target.value;
                          setReport({ ...report, reportProgress: report.reportProgress });
                        }}
                        disabled={report.isApproval}
                      />
                    </Col>
                    <Col xs={1} className={style.slashSeparate}>
                      ／
                    </Col>
                    <Col xs={5}>
                      <FNum
                        suffix="Ｐ"
                        value={r.maxPage}
                        change={(e) => {
                          report.reportProgress[i].maxPage = e.target.value;
                          setReport({ ...report, reportProgress: report.reportProgress });
                        }}
                        disabled={report.isApproval}
                      />
                    </Col>
                  </Row>
                </FItemGroup>
              ) : (
                <></>
              )}
              {window.env.report.learningUnit ? (
                <FItemGroup
                  label={'単元単位'}
                  style={{ marginLeft: '1rem' }}
                  errors={errors}
                  paths={[`reportProgress[${i}].learningUnit`]}
                >
                  <FNote
                    rows={5}
                    value={r.learningUnit}
                    change={(e) => {
                      report.reportProgress[i].learningUnit = e.target.value;
                      setReport({ ...report, reportProgress: report.reportProgress });
                    }}
                    disabled={report.isApproval}
                  />
                </FItemGroup>
              ) : (
                <></>
              )}
              <FItemGroup label="理解度" className={style.spaceB} style={{ marginLeft: '1rem' }}>
                <Col xs={3}>
                  <FSel
                    options={pointRateSelection}
                    value={r.points}
                    change={(e) => {
                      report.reportProgress[i].points = e.target.value;
                      setReport({ ...report, reportProgress: report.reportProgress });
                    }}
                    disabled={report.isApproval}
                  />
                </Col>
              </FItemGroup>
            </React.Fragment>
          );
        })}
        {!report.isApproval && (
          <Btn outline prefixIcon="faPlus" click={addNewText}>
            報告テキスト追加
          </Btn>
        )}
        <FItemGroup label="指導内容">
          <FNote
            rows={5}
            value={report.content}
            change={(e) => setReport({ ...report, content: e.target.value })}
            disabled={report.isApproval}
          />
        </FItemGroup>
      </Section>
      <Section title="評価について">
        <FItemGroup label="やる気・モチベーション">
          <div style={{ marginLeft: '1rem' }}>
            <FRadio
              options={pointsSelection}
              gName="point"
              value={report.teacherEvaluation}
              change={(e) => setReport({ ...report, teacherEvaluation: e.target.value })}
              disabled={report.isApproval}
            />
          </div>
        </FItemGroup>
      </Section>
      <Section title="料金について">
        <FItemGroup label="指導料金" className={style.spaceB} errors={errors} paths={['fee']}>
          <Col>
            <FNum
              suffix="円"
              value={report.fee}
              change={(e) => setReport({ ...report, fee: e.target.value })}
              disabled={report.isApproval}
            />
          </Col>
        </FItemGroup>
        <FItemGroup label="交通費" errors={errors} paths={['travelCost']}>
          <Col>
            <FNum
              suffix="円"
              value={report.travelCost}
              change={(e) => setReport({ ...report, travelCost: e.target.value })}
              disabled={report.isApproval}
            />
          </Col>
        </FItemGroup>
      </Section>
      <Section title="次回予定">
        <FItemGroup
          labels={[
            { label: '指導日時', size: 5 },
            { label: '開始時間', size: 6 },
          ]}
          full={false}
          className={style.spaceB}
          errors={errors}
          paths={['nextDate']}
        >
          <Col xs={5} style={{ paddingRight: 0 }}>
            <FDate
              value={report.nextDate}
              change={(e) => setReport({ ...report, nextDate: e.target.value })}
              disabled={report.isApproval}
            />
          </Col>
          <Col xs={3} style={{ paddingRight: 0 }}>
            <FSel
              options={hours}
              value={report.nextHour}
              change={(e) => setReport({ ...report, nextHour: e.target.value })}
              disabled={report.isApproval}
            />
          </Col>
          <Col xs={3} style={{ paddingRight: 0 }}>
            <FSel
              options={minutes}
              value={report.nextMinute}
              change={(e) => setReport({ ...report, nextMinute: e.target.value })}
              disabled={report.isApproval}
            />
          </Col>
        </FItemGroup>
        <FItemGroup label="指示内容">
          <Col>
            <FNote
              rows={5}
              disabled={report.isApproval}
              value={report.nextInstructions}
              change={(e) => setReport({ ...report, nextInstructions: e.target.value })}
            />
          </Col>
        </FItemGroup>
      </Section>
      <Section title="アンケート">
        {report.teacherQuestionnaires.inquiries &&
          report.teacherQuestionnaires.inquiries.map((r, i) => {
            return (
              <React.Fragment key={i}>
                <FItemGroup label={r.inquiry} className={style.spaceB}>
                  <div style={{ marginLeft: '1rem' }}>
                    {report.isTurnIn ? (
                      r.answer ? (
                        r.answer
                      ) : (
                        '未回答'
                      )
                    ) : (
                      <FRadio
                        options={r.answers.map((rr) => {
                          return {
                            value: rr,
                            label: rr,
                          };
                        })}
                        value={r.answer}
                        gName={`inquiry${i}`}
                        change={(e) => {
                          report.teacherQuestionnaires.inquiries[i].answer = e.target.value;
                          setReport({ ...report, teacherQuestionnaires: report.teacherQuestionnaires });
                        }}
                      />
                    )}
                  </div>
                </FItemGroup>
              </React.Fragment>
            );
          })}
      </Section>
      <Section title="コメント">
        <FItemGroup label="家庭へのコメント">
          <Col>
            <FNote
              rows={5}
              value={report.teacherToFamilyComment}
              change={(e) => setReport({ ...report, teacherToFamilyComment: e.target.value })}
              disabled={report.isTurnIn}
            />
          </Col>
        </FItemGroup>
        <FItemGroup label={window.env.company.shortBrandName + 'に相談があれば（任意）\r\n※家庭には通知されません'}>
          <Col>
            <FNote
              rows={5}
              value={report.teacherToHeadquartersComment}
              change={(e) => setReport({ ...report, teacherToHeadquartersComment: e.target.value })}
              disabled={report.isTurnIn}
            />
          </Col>
        </FItemGroup>
        <FItemGroup label="家庭からのコメント">
          <Col>
            <FNote rows={5} value={report.familyToTeacherComment} disabled={true} />
          </Col>
        </FItemGroup>
      </Section>
      <Section title="テスト・成績">
        <Row style={{ marginTop: '1rem' }}>
          <Col>
            <Btn children="新しいテスト" click={showExamModalFunc} color="success" prefixIcon="faPlus" />
          </Col>
          <Col>
            <Btn children="新しい成績" click={showRecordModalFunc} color="success" prefixIcon="faPlus" />
          </Col>
        </Row>
      </Section>
      <hr />
      <Row style={{ textAlign: 'right', marginTop: '2rem', marginBottom: '2rem' }}>
        <Col>
          {!report.isTurnIn && (
            <>
              <Btn
                outline
                style={{ marginBottom: '1rem' }}
                click={() => {
                  handleShowConfirm(
                    '下書き保存確認',
                    '入力した内容で下書き保存します。よろしいですか？',
                    '下書き保存する',
                    storeForDraft
                  );
                }}
                prefixIcon="faFloppyDisk"
                children="下書き保存"
              />
              <Btn
                outline
                style={{ marginBottom: '1rem', color: 'red' }}
                click={() => {
                  handleShowConfirm(
                    '下書き削除',
                    '下書きを削除します。よろしいですか？',
                    '下書き削除する',
                    deleteDraft
                  );
                }}
                prefixIcon="faTrash"
                children="下書き削除"
              />
            </>
          )}
          {!report.isApproval && (
            <Btn
              style={{ marginBottom: '1rem' }}
              click={() => {
                handleShowConfirm('提出確認', '入力した内容で提出します。よろしいですか？', '提出する', storeForSubmit);
              }}
              prefixIcon="faShareFromSquare"
              children="提出"
            />
          )}
          <Btn click={onBack} color="secondary" outline children="戻る" prefixIcon="faAnglesLeft" />
        </Col>
      </Row>
      <ConfirmModal
        show={confirmModal.show}
        onClose={handleCloseConfirm}
        onSubmit={async () => confirmModal.handler()}
        title={confirmModal.title}
        content={confirmModal.content}
        footerButtonTitle={confirmModal.buttonTitle}
        showSubmitLoading={true}
      />
    </Form>
  );
};

export default GoalReportForm;
