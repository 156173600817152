import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import { getExams, getLatestExam, getExamImage, addExam } from '@/api/index';
import { FTxt, FNum, FDate, Btn, Ic } from '@/components/basic/index';
import { FItemGroup, DateFormat, Toast } from '@/components/shared/index';
import style from '@/pages/report/Report.scss';
import { Exam, ExamSubject } from '@/entity/Report';
import { Validator, Rule } from '@/util/validate';
import { isEmpty } from '@/util/util';
import ImageModal from './ImageModal';
import EditModal from './EditModal';
import { isGoal } from '@/util/company';

const Exams = ({ studentId, showModal, onShow = () => {}, onClose = () => {} }) => {
  const [image, setImage] = useState({ show: false, file: null, title: null });
  const [exams, setExams] = useState([]);
  const [exam, setExam] = useState(new Exam());
  const [errors, setErrors] = useState({});

  const tests = ['単元テスト', '中間テスト', '期末テスト', '実力テスト', '模試・診断'];

  const fillExams = async (id) => {
    const data = await getExams(id);
    setExams(data);
  };
  const setLatestExamSubjects = async (id) => {
    const d = await getLatestExam(id);
    if (!d) return;
    setExam(() => new Exam(d, true));
  };

  const showImage = async (examId, imageId, name, mime) => {
    const data = await getExamImage(studentId, examId, imageId);
    const reader = new FileReader();
    reader.onload = () => {
      const b64 = reader.result;
      setImage({ ...image, show: true, file: b64.replace('application/octet-stream', mime), title: name });
    };
    reader.readAsDataURL(data);
  };
  const closeImage = () => setImage({ ...image, show: false, file: null, title: null });

  const validate = () => {
    const validate = new Validator(exam);
    validate.exec('date', 'テスト実施日', [Rule.require()]);
    validate.exec('name', 'テスト名', [Rule.require()]);
    exam.examSubjects.forEach((_, i) => {
      validate.exec(`examSubjects[${i}].subjectName`, '科目名', [Rule.require()]);
      validate.exec(`examSubjects[${i}].score`, '点数', [Rule.require(), Rule.num(), Rule.numRange(0, 999)]);
      validate.exec(`examSubjects[${i}].maxScore`, '最大点数', [
        Rule.require(),
        Rule.num(),
        Rule.numRange(0, 999),
        Rule.custom((value) => {
          const compareValue = exam.examSubjects[i].score;
          if (isEmpty(compareValue) || isEmpty(value)) return false;
          if (Number(compareValue) > Number(value)) return true;
        }, '最大は点数より大きな値を入力してください。'),
      ]);
    });
    setErrors(validate.getErrors());
    return validate.inValid();
  };
  const storeExam = async () => {
    if (validate()) return;
    try {
      await addExam(studentId, exam);
      await fillExams(studentId);
      Toast.success('試験を追加しました');
      onClose();
    } catch (e) {
      console.error(e);
    }
  };
  const newRowExamSubject = () => {
    exam.examSubjects.push(new ExamSubject());
    setExam({ ...exam });
  };
  const removeRowExamSubject = (idx) =>
    setExam({ ...exam, examSubjects: exam.examSubjects.filter((_, i) => i !== idx) });

  useEffect(() => {
    fillExams(studentId);
  }, []);

  useEffect(async () => {
    if (showModal) {
      setErrors({});
      const name = isGoal() ? tests[0] : '';
      setExam(new Exam({ name }));
      await setLatestExamSubjects(studentId);
    }
  }, [showModal]);

  return (
    <Container fluid>
      <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Col style={{ textAlign: 'right' }}>
          <Btn children="新しいテスト" click={onShow} color="success" prefixIcon="faPlus" />
        </Col>
      </Row>
      {exams.map((r, i) => {
        return (
          <div key={i + 'test-n'}>
            <Card className={[style.card]}>
              <Card.Title>
                <DateFormat date={r.date} />
              </Card.Title>
              <Card.Subtitle>{r.name}</Card.Subtitle>
              <Card.Body>
                <table className={style.scoreTable}>
                  <tbody>
                    {r.examSubjects.map((rr, ii) => {
                      return (
                        <React.Fragment key={ii + 'exam'}>
                          <tr>
                            <td className={style.subject}>{rr.subjectName}</td>
                            <td>
                              <span style={{ fontSize: '1.3rem', border: 'none' }}>{rr.score}</span>
                              <span style={{ fontSize: '0.5rem', border: 'none', marginRight: '0.2rem' }}>
                                /{rr.maxScore}
                              </span>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
                {r.examImages.map((rr, ii) => {
                  return (
                    <React.Fragment key={ii + 'exam'}>
                      <div className={style.imageLink}>
                        <a onClick={() => showImage(r.id, rr.id, rr.name, rr.mime)}>
                          <Ic iName="faImage" />
                          {rr.name}
                        </a>
                      </div>
                    </React.Fragment>
                  );
                })}
              </Card.Body>
            </Card>
          </div>
        );
      })}
      <ImageModal show={image.show} title={image.title} file={image.file} onClose={closeImage} />
      <EditModal
        show={showModal}
        title="テスト"
        onClose={onClose}
        onRegister={storeExam}
        registerBtnDisabled={exam.examSubjects.length === 0}
      >
        <FItemGroup label="テスト実施日" className={style.spaceB} errors={errors} paths={['date']}>
          <Col>
            <FDate value={exam.date} change={(e) => setExam({ ...exam, date: e.target.value })} />
          </Col>
        </FItemGroup>
        <FItemGroup label="テスト名" className={style.spaceB} errors={errors} paths={['name']}>
          <Col>
            {isGoal() && (
              <>
                <Form.Select value={exam.name} onChange={(e) => setExam({ ...exam, name: e.target.value })}>
                  {_.map(tests, (t) => {
                    return (
                      <React.Fragment key={t}>
                        <option value={t}>{t}</option>
                      </React.Fragment>
                    );
                  })}
                </Form.Select>
              </>
            )}
            {!isGoal() && (
              <>
                <FTxt value={exam.name} change={(e) => setExam({ ...exam, name: e.target.value })} />
              </>
            )}
          </Col>
        </FItemGroup>
        {exam.examSubjects.map((r, i) => {
          return (
            <React.Fragment key={i.toString()}>
              <FItemGroup
                label="科目"
                className={style.spaceB}
                errors={errors}
                paths={[`examSubjects[${i}].subjectName`, `examSubjects[${i}].score`, `examSubjects[${i}].maxScore`]}
              >
                <Row>
                  <Col xs={10}>
                    <FTxt
                      value={r.subjectName}
                      style={{ marginBottom: '0.2rem' }}
                      change={(e) => {
                        exam.examSubjects[i].subjectName = e.target.value;
                        setExam({ ...exam, examSubjects: exam.examSubjects });
                      }}
                    />
                  </Col>
                  <Col xs={1}>
                    <Btn
                      isCustom
                      outline
                      prefixIcon="faXmark"
                      click={() => removeRowExamSubject(i)}
                      width="30px"
                      height="30px"
                      className={style.xmarkButton}
                    />
                  </Col>
                </Row>
                <Col xs={1} className={style.spaceB}></Col>
                <Row>
                  <Col xs={5}>
                    <FNum
                      suffix="点"
                      value={r.score}
                      change={(e) => {
                        exam.examSubjects[i].score = e.target.value;
                        setExam({ ...exam, examSubjects: exam.examSubjects });
                      }}
                    />
                  </Col>
                  <Col xs={1} className={style.slashSeparate}>
                    ／
                  </Col>
                  <Col xs={5}>
                    <FNum
                      suffix="点"
                      value={r.maxScore}
                      change={(e) => {
                        exam.examSubjects[i].maxScore = e.target.value;
                        setExam({ ...exam, examSubjects: exam.examSubjects });
                      }}
                    />
                  </Col>
                </Row>
              </FItemGroup>
            </React.Fragment>
          );
        })}
        <Btn outline prefixIcon="faPlus" click={newRowExamSubject}>
          新しい科目を追加
        </Btn>
      </EditModal>
    </Container>
  );
};

Exams.propTypes = {
  studentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showModal: PropTypes.bool,
  onShow: PropTypes.func,
  onClose: PropTypes.func,
};
export default Exams;
