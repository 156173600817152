import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Container } from 'react-bootstrap';
import _ from 'lodash';
import style from './Matter.scss';
import { apply, disApply, getApplicated, getSagashiList } from '@/api/index';
import Layout from '@/layout/Layout';
import { Btn, FTxt, Ic } from '@/components/basic/index';

const Matter = () => {
  const [sagashiList, setSagashiList] = useState([]);
  const [applyList, setApplyList] = useState([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    (async () => {
      refreshData();
    })();
  }, []);

  const refreshData = async () => {
    const sagashiList = await getSagashiList();
    setSagashiList(sagashiList);

    const applyList = await getApplicated();
    setApplyList(applyList);
  };

  const narrowdSagashiList = () => {
    return _.filter(sagashiList, sagashiFilter);
  };

  const sagashiFilter = (sagashi) => {
    if (search === '') {
      return true;
    }
    return (
      _.filter(
        [
          sagashi.student.family.prefecture,
          sagashi.student.family.address1,
          sagashi.student.family.notice1,
          sagashi.student.family.bus,
          sagashi.student.family.station,
          sagashi.student.teachTravelCost,
          sagashi.student.grade,
          sagashi.student.gender,
        ],
        (s) => {
          return s.includes(search);
        }
      ).length > 0
    );
  };

  const isApply = (sagashiId) => {
    return _.isEmpty(_.find(applyList, { sagashiId }));
  };

  const doApply = async (sagashiId) => {
    await apply({ sagashiId });
    await refreshData();
  };

  const doDisApply = async (sagashiId) => {
    await disApply({ sagashiId });
    await refreshData();
  };

  const noticeRender = (v) => {
    return (v || '').split('\n').map((r, i) => {
      return (
        <React.Fragment key={i}>
          {r} <br />
        </React.Fragment>
      );
    });
  };

  return (
    <Layout>
      <Container fluid className={style.wrapper}>
        <div className={style.reportEditPanel}>
          <Row className={style.header}>
            <Col className={style.pageTitle}>案件情報</Col>
          </Row>
        </div>
        <div>
          {sagashiList.length} 件中 {narrowdSagashiList().length} 件の案件情報を表示中。
        </div>
        <div className={style.searchBox}>
          <FTxt
            value={search}
            change={(e) => {
              setSearch(e.target.value);
            }}
            placeholder={'案件情報の絞り込み'}
          />
        </div>
        {narrowdSagashiList().map((m, i) => {
          return (
            <Card key={i} className={style.card}>
              <Card.Body className={style.body}>
                <Row>
                  <Col xs={7} className={style.title}>
                    {m.student.gender} ({m.student.grade})
                  </Col>
                  <Col xs={5} style={{ textAlign: 'right', paddingTop: '0.5rem' }}>
                    <Btn
                      isCustom
                      className={isApply(m.id) ? style.reqBtn : style.cancelBtn}
                      width="100px"
                      height="25px"
                      click={isApply(m.id) ? async () => await doApply(m.id) : async () => await doDisApply(m.id)}
                      prefixIcon={isApply(m.id) ? 'faPaperPlane' : 'faXmark'}
                      showLoading={true}
                    >
                      <span>{isApply(m.id) ? '応募する' : 'やめる'}</span>
                    </Btn>
                  </Col>
                </Row>
                <Row>
                  <Col xs={1} className={style.itemLabel}>
                    <Ic iName="faLocationDot" />
                  </Col>
                  <Col xs={9}>{m.address}</Col>
                </Row>
                <Row>
                  <Col xs={2} className={style.itemLabel}>
                    交通
                  </Col>
                  <Col xs={9}>{m.student.family.station}</Col>
                </Row>
                <Row>
                  <Col xs={{ span: 9, offset: 2 }}>{m.student.family.bus}</Col>
                </Row>
                <Row>
                  <Col xs={{ span: 9, offset: 2 }}>{m.student.teachTravelCost}</Col>
                </Row>
                <Row>
                  <Col xs={2} className={style.itemLabel}>
                    備考
                  </Col>
                  <Col xs={9}>{noticeRender(m.student.family.notice1)}</Col>
                </Row>
              </Card.Body>
            </Card>
          );
        })}
      </Container>
    </Layout>
  );
};
export default Matter;
