import PropTypes from 'prop-types';
import * as SolidIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = ({ iName, style, className }) => {
  return <FontAwesomeIcon icon={SolidIcons[iName]} style={style} className={className} />;
};

Icon.propTypes = {
  iName: PropTypes.oneOf([
    'faAnglesLeft',
    'faAnglesRight',
    'faFloppyDisk',
    'faShareFromSquare',
    'faFileLines',
    'faBars',
    'faPlus',
    'faBus',
    'faTrain',
    'faPenToSquare',
    'faMobileScreenButton',
    'faXmark',
    'faArrowRightToBracket',
    'faPaperPlane',
    'faRotateRight',
    'faPen',
    'faChartLine',
    'faHouse',
    'faPhone',
    'faClipboard',
    'faLocationDot',
    'faYenSign',
    'faFolder',
    'faBook',
    'faFilm',
    'faImage',
    'faPlay',
    'faPause',
    'faExpand',
    'faCompress',
    'faFile',
    'faCheck',
    'faCircleExclamation',
    'faCircleCheck',
  ]).isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default Icon;
