import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import basicStyle from './Basic.scss';

const FormSelect = ({
  options,
  size,
  disabled,
  value,
  className,
  style,
  valueField,
  labelField,
  error,
  prefix,
  suffix,
  change,
  require = false,
}) => {
  const classNames = () => {
    const requireClass = `${value}` === '' && require;
    return [basicStyle.glDate, className, requireClass ? basicStyle.requireInput : ''];
  };
  const inValid = error !== null && error !== undefined && error !== '';
  return (
    <InputGroup size={size}>
      {prefix ? <InputGroup.Text>{prefix}</InputGroup.Text> : <></>}
      <Form.Select
        className={classNames().filter(Boolean).join(' ')}
        disabled={disabled}
        size={size}
        style={style}
        value={value}
        onChange={(e) => {
          const row = options.find((r) => r[valueField] === e.target.value);
          change(e, row);
        }}
      >
        <option></option>
        {options.map((r, i) => {
          return (
            <option value={r[valueField]} key={i}>
              {r[labelField]}
            </option>
          );
        })}
      </Form.Select>
      {suffix ? <InputGroup.Text>{suffix}</InputGroup.Text> : <></>}
      {inValid ? <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback> : <></>}
    </InputGroup>
  );
};

FormSelect.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  valueField: PropTypes.string,
  labelField: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  change: PropTypes.func,
  prefix: PropTypes.string,
  suffix: PropTypes.node,
  error: PropTypes.string,
};

FormSelect.defaultProps = {
  size: 'sm',
  disabled: false,
  valueField: 'value',
  labelField: 'label',
  change: () => {},
  error: null,
};

export default FormSelect;
