import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import basicStyle from './Basic.scss';

const FormCheck = ({
  gName,
  options,
  size,
  disabled,
  value,
  className,
  style,
  valueField,
  labelField,
  error,
  change = (v) => {},
  require = false,
}) => {
  const classNames = () => {
    const requireClass = `${value}` === '' && require;
    return ['cst-select', basicStyle.glText, className, requireClass ? basicStyle.requireInput : ''];
  };
  const inValid = error !== null && error !== undefined && error !== '';
  const onChange = (e) => {
    if (e.target.checked) {
      change([...value, e.target.value]);
    } else {
      _.pull(value, e.target.value);
      change([...value]);
    }
  };

  return (
    <div>
      {options.map((r) => {
        return (
          <React.Fragment key={r[valueField]}>
            <InputGroup size={size}>
              <Form.Check
                className={classNames().filter(Boolean).join(' ')}
                style={style}
                disabled={disabled}
                value={r.value}
                label={r[labelField]}
                name={gName}
                type="checkbox"
                id={`${gName}-${r[valueField]}`}
                onChange={onChange}
                checked={_.includes(value, r.value)}
              />
            </InputGroup>
          </React.Fragment>
        );
      })}
      {inValid ? <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback> : <></>}
    </div>
  );
};

FormCheck.propTypes = {
  gName: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  valueField: PropTypes.string,
  labelField: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.array.isRequired,
  change: PropTypes.func,
  error: PropTypes.string,
};

FormCheck.defaultProps = {
  size: 'sm',
  disabled: false,
  valueField: 'value',
  labelField: 'label',
  change: () => {},
  error: null,
};

export default FormCheck;
