import _ from 'lodash';
import camelcaseKeys from 'camelcase-keys';
import Teacher from '@/entity/Teacher';
import { toCamelCase, apiBase } from './ApiBase';
import Student from '@/entity/Student';
import { Exam, Record, Report, reportForApi, Settlement, TextBook } from '@/entity/Report';

export const auth = async ({ userId, password }) => {
  const result = await apiBase.post(`/api/v1/auth`, { user_id: userId, password });
  return result.data.token;
};

export const me = async () => {
  try {
    const result = await apiBase.get(`/api/v1/me`);
    return new Teacher(camelcaseKeys(result.data, { deep: true }));
  } catch (_) {
    return null;
  }
};

export const forgotPassword = async ({ mail }) => {
  const result = await apiBase.post(`/api/v1/forgotPassword`, { mail });
  return result.data;
};

export const verifyResetToken = async ({ resetToken }) => {
  const result = await apiBase.post(`/api/v1/verifyResetToken`, { resetToken });
  return result.data;
};

export const resetPassword = async ({ resetToken, password }) => {
  const result = await apiBase.put(`/api/v1/resetPassword`, { resetToken, password });
  return result.data;
};

export const getSagashiList = async () => {
  const result = await apiBase.get(`/api/v1/mySagashi`);
  return camelcaseKeys(result.data, { deep: true });
};

export const getResponderList = async () => {
  const result = await apiBase.get(`/api/v1/myResponder`);
  return _.filter(camelcaseKeys(result.data, { deep: true }), (t) => t.student !== null);
};

export const getApplicated = async () => {
  const result = await apiBase.get(`/api/v1/myApply`);
  return camelcaseKeys(result.data, { deep: true });
};

export const apply = async ({ sagashiId }) => {
  const result = await apiBase.post(`/api/v1/apply/${sagashiId}`);
  return camelcaseKeys(result.data, { deep: true });
};

export const disApply = async ({ sagashiId }) => {
  const result = await apiBase.delete(`/api/v1/disApply/${sagashiId}`);
  return camelcaseKeys(result.data, { deep: true });
};

export const updateProfile = async ({ teacher }) => {
  const result = await apiBase.put(`/api/v1/profile`, { teacher });
  return camelcaseKeys(result.data, { deep: true });
};

export const getKarte = async ({ studentId }) => {
  const result = await apiBase.get(`/api/v1/student/${studentId}/karte`);
  return camelcaseKeys(result.data, { deep: true });
};

export const getStudent = async ({ id }) => {
  const result = await apiBase.get(`/api/v1/student/${id}`);
  return new Student(camelcaseKeys(result.data, { deep: true }));
};

export const getReport = async (studentId, reportId) => {
  const { data } = await apiBase.get(`/api/v1/students/${studentId}/reports/${reportId}`);
  return new Report(toCamelCase(data));
};
export const getReports = async (studentId) => {
  const { data } = await apiBase.get(`/api/v1/students/${studentId}/reports`);
  return toCamelCase(data).map((r) => new Report(r, true));
};
export const getReportAllIds = async (studentId) => {
  const { data } = await apiBase.get(`/api/v1/students/${studentId}/reportIds`);
  return data.reverse();
};

export const addReport = async (studentId, report) => {
  const { data } = await apiBase.post(`/api/v1/students/${studentId}/reports`, reportForApi(report));
  return new Report(toCamelCase(data));
};

export const updateReport = async (studentId, reportId, report) => {
  const { data } = await apiBase.put(`/api/v1/students/${studentId}/reports/${reportId}`, reportForApi(report));
  return new Report(toCamelCase(data));
};

export const deleteReport = async (studentId, reportId) => {
  apiBase.delete(`/api/v1/students/${studentId}/reports/${reportId}`);
};

export const getPurchaseTexts = async (studentId) => {
  const { data } = await apiBase.get(`/api/v1/students/${studentId}/textbooks`);
  return toCamelCase(data).map((r) => new TextBook(r));
};

export const getExams = async (studentId) => {
  const { data } = await apiBase.get(`/api/v1/students/${studentId}/exams`);
  return toCamelCase(data).map((r) => new Exam(r));
};
export const getExam = async (studentId, examId) => {
  const { data } = await apiBase.get(`/api/v1/students/${studentId}/exams/${examId}`);
  return new Exam(toCamelCase(data));
};
export const getLatestExam = async (studentId) => {
  const { data } = await apiBase.get(`/api/v1/students/${studentId}/exam/latest`);
  if (data) {
    return new Exam(toCamelCase(data));
  }
  return null;
};
export const addExam = async (studentId, exam) => {
  const { data } = await apiBase.post(`/api/v1/students/${studentId}/exams`, exam);
  return new Exam(toCamelCase(data));
};
export const getExamImage = async (studentId, examId, imageId) => {
  const { data } = await apiBase.get(
    `/api/v1/students/${studentId}/exams/${examId}/images/${imageId}`,
    {},
    { responseType: 'blob' }
  );
  return data;
};

export const getRecords = async (studentId) => {
  const { data } = await apiBase.get(`/api/v1/students/${studentId}/records`);
  return toCamelCase(data).map((r) => new Record(r));
};
export const getRecord = async (studentId, examId) => {
  const { data } = await apiBase.get(`/api/v1/students/${studentId}/records/${examId}`);
  return new Record(toCamelCase(data));
};
export const getLatestRecord = async (studentId) => {
  const { data } = await apiBase.get(`/api/v1/students/${studentId}/record/latest`);
  if (data) {
    return new Record(toCamelCase(data));
  }
  return null;
};
export const addRecord = async (studentId, exam) => {
  const { data } = await apiBase.post(`/api/v1/students/${studentId}/records`, exam);
  return new Record(toCamelCase(data));
};
export const getRecordImage = async (studentId, examId, imageId) => {
  const { data } = await apiBase.get(
    `/api/v1/students/${studentId}/records/${examId}/images/${imageId}`,
    {},
    { responseType: 'blob' }
  );
  return data;
};

export const getUnsettledReports = async (familyId) => {
  const { data } = await apiBase.get(`/api/v1/families/${familyId}/reports/unsettled`);
  return toCamelCase(data).map((r) => new Report(r, true));
};

export const getSettlement = async (familyId, id) => {
  const { data } = await apiBase.get(`/api/v1/families/${familyId}/settlements/${id}`);
  return new Settlement(toCamelCase(data));
};
export const getSettlementAllIds = async (familyId) => {
  const { data } = await apiBase.get(`/api/v1/families/${familyId}/settlementIds`);
  return data.reverse();
};
export const addSettlement = async (familyId, settlement) => {
  const { data } = await apiBase.post(`/api/v1/families/${familyId}/settlements`, settlement);
  return data;
};
// export const updateSettlement = (familyId, id, settlement) => {};
export const paymented = async (familyId, id, settlement) => {
  const { data } = await apiBase.put(`/api/v1/families/${familyId}/settlements/${id}/payment`, settlement);
  return data;
};

export const getCoachingDocuments = async ({ id }) => {
  const result = await apiBase.get(`/api/v1/coachingDocuments`, { id });
  return camelcaseKeys(result.data, { deep: true });
};

export const getCoachingDocumentTextbook = async ({ id }) => {
  const result = await apiBase.get(`/api/v1/coachingDocuments/${id}/textbook`, {}, { responseType: 'blob' });
  return result.data;
};

export const saveViewing = async (coacingDocumentId, viewingHistory) => {
  const { data } = await apiBase.post(`/api/v1/coachingDocuments/${coacingDocumentId}/viewing`, viewingHistory);
  return data;
};

export const getCoachingDocumentWithViewingRecord = async (coacingDocumentId) => {
  const { data } = await apiBase.get(`/api/v1/coachingDocuments/${coacingDocumentId}/viewing`);
  return toCamelCase(data);
};
