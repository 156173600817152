import _ from 'lodash';
import PropTypes from 'prop-types';
import basicStyle from '@/components/basic/Basic.scss';
import { Form, InputGroup } from 'react-bootstrap';
import { FTxt } from '@/components/basic/index';
import React, { useEffect, useState } from 'react';

const SelectOrInput = ({
  options,
  size,
  disabled,
  value,
  className,
  style,
  valueField,
  labelField,
  error,
  prefix,
  suffix,
  change,
  otherValue,
  require = false,
}) => {
  const [selectedOptionValue, setSelectedOptionValue] = useState('');
  const [extraInputValue, setExtraInputValue] = useState('');

  const classNames = () => {
    const requireClass = `${value}` === '' && require;
    return ['cst-select', basicStyle.glText, className, requireClass ? basicStyle.requireInput : ''];
  };
  const inValid = error !== null && error !== undefined && error !== '';

  const isCustomValue = () => {
    const selected = _.find(options, (o) => {
      return o.value === value;
    });
    if (!selected) {
      return true;
    }
    return selected.value === otherValue;
  };

  useEffect(() => {
    (async () => {
      if (isCustomValue()) {
        setSelectedOptionValue(otherValue);
        setExtraInputValue(value);
      } else {
        setSelectedOptionValue(value);
        setExtraInputValue('');
      }
    })();
  }, [value]);

  const onSelect = (event, option = {}) => {
    setSelectedOptionValue(option.value);
    change(event);
  };

  const onInput = (event) => {
    setExtraInputValue(event.target.value);
    change(event);
  };

  return (
    <InputGroup size={size}>
      {prefix ? <InputGroup.Text>{prefix}</InputGroup.Text> : <></>}
      <Form.Select
        className={classNames().filter(Boolean).join(' ')}
        disabled={disabled}
        size={size}
        style={style}
        value={selectedOptionValue}
        onChange={(e) => {
          const row = options.find((r) => r[valueField] === e.target.value);
          onSelect(e, row);
        }}
      >
        <option></option>
        {options.map((r, i) => {
          return (
            <option value={r[valueField]} key={i}>
              {r[labelField]}
            </option>
          );
        })}
      </Form.Select>
      {selectedOptionValue === otherValue && (
        <>
          <FTxt
            placeholder={`${otherValue}の場合は記入してください`}
            value={extraInputValue}
            change={onInput}
            error=""
          />
        </>
      )}
      {suffix ? <InputGroup.Text>{suffix}</InputGroup.Text> : <></>}
      {inValid ? <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback> : <></>}
    </InputGroup>
  );
};

SelectOrInput.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  valueField: PropTypes.string,
  labelField: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  change: PropTypes.func,
  prefix: PropTypes.string,
  suffix: PropTypes.node,
  error: PropTypes.string,
  otherValue: PropTypes.string,
};

SelectOrInput.defaultProps = {
  size: 'sm',
  disabled: false,
  valueField: 'value',
  labelField: 'label',
  change: () => {},
  error: null,
};

export default SelectOrInput;
