import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import basicStyle from './Basic.scss';

const FormRadioAndDetail = ({
  gName,
  options,
  detailOption,
  size,
  disabled,
  value,
  className,
  style,
  valueField,
  labelField,
  error,
  change,
}) => {
  const classNames = ['cst-select', basicStyle.glRadio, className].join(' ');
  const inValid = error !== null && error !== undefined && error !== '';
  const [checkValue, setCheckValue] = useState('');

  useEffect(() => {
    if (value === detailOption) {
      setCheckValue(detailOption);
      return;
    }
    if (_.filter(options, { value }).length === 0) {
      setCheckValue(detailOption);
    } else {
      setCheckValue(value);
    }
  }, [value]);

  const onRadioChange = (e) => {
    setCheckValue(e.target.value);
    change(e.target.value === detailOption ? '' : e.target.value);
  };

  return (
    <>
      {options.map((r) => {
        return (
          <React.Fragment key={r[valueField]}>
            <InputGroup size={size}>
              <Form.Check
                className={classNames}
                style={style}
                disabled={disabled}
                value={r.value}
                label={r[labelField]}
                name={gName}
                type="radio"
                id={`${gName}-${r[valueField]}`}
                onChange={onRadioChange}
                checked={r.value === checkValue}
              />
            </InputGroup>
          </React.Fragment>
        );
      })}
      {checkValue === detailOption && (
        <Form.Control
          type="text"
          className={classNames}
          disabled={disabled}
          value={value}
          size={size}
          style={style}
          onChange={(e) => {
            change(e.target.value);
          }}
          isInvalid={inValid}
          placeholder={`「${detailOption}」の場合は詳細を記入してください`}
        />
      )}
      {inValid ? <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback> : <></>}
    </>
  );
};

FormRadioAndDetail.propTypes = {
  gName: PropTypes.string.isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  detailOption: PropTypes.string,
  disabled: PropTypes.bool,
  valueField: PropTypes.string,
  labelField: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  change: PropTypes.func,
  error: PropTypes.string,
};

FormRadioAndDetail.defaultProps = {
  size: 'sm',
  disabled: false,
  valueField: 'value',
  labelField: 'label',
  change: () => {},
  error: null,
};

export default FormRadioAndDetail;
