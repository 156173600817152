import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';
import { me, updateProfile } from '@/api';
import Layout from '@/layout/Layout';
import Teacher from '@/entity/Teacher';
import { gender, prefecture } from '@/util/constants';
import { Btn, FCheck, FDate, FNote, FNum, FSel, FTxt } from '@/components/basic';
import { FItemGroup, Toast } from '@/components/shared';
import style from '../Profile.scss';
import TemporaryFile from '@/entity/File';
import { getFileContent, uploadTemporaryFile } from '@/api/file';
import { getAddress } from '@/util/address';
import SelectOrInput from '@/components/basic/SelectOrInput';
import { Rule, Validator } from '@/util/validate';

const GoodEdit = () => {
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [profile, setProfile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const profileImageRef = useRef(null);
  const resumeFileRef = useRef(null);
  const [profileImage, setProfileImage] = useState(null);
  const [resumeImage, setResumeImage] = useState(null);

  const schoolYears = ['1年', '2年', '3年', '4年', '5年', '6年', '修士1年', '修士2年', '博士以上', '卒業'];

  // なんか100ミリ秒遅らさないとページトップにいかない。
  const toTopPos = () => setTimeout(() => window.scrollTo(0, 0), 100);

  useEffect(() => {
    (async () => {
      const myProfile = await me();
      if (myProfile.pr === '') {
        myProfile.pr =
          '【中学校・高校での通塾体験や受験を通して学んだこと】\n' +
          '【今まで指導経験】\n' +
          '【指導した生徒の合格実績】\n' +
          '【生徒を指導する上で心がけていること】\n' +
          '【自己PR（趣味・特技・資格など）】\n' +
          '【大学卒業年度(既卒の方のみ)】';
      }
      const profileFile = myProfile.profileFile();
      if (profileFile) {
        setProfileImage(await contentsOf({ id: profileFile.id }));
      }
      const profileFileId = profileFile ? profileFile.id : null;
      const resumeFile = myProfile.resumeFile();
      if (resumeFile) {
        setResumeImage(await contentsOf({ id: resumeFile.id }));
      }
      const resumeFileId = resumeFile ? resumeFile.id : null;
      setProfile(new Teacher({ ...myProfile, profileFileId, resumeFileId }));
    })();
  }, []);

  const contentsOf = async ({ id }) => {
    const blob = await getFileContent({ id });
    return URL.createObjectURL(blob);
  };

  const canFillAddress = () => {
    return (profile.zip1 || '').length === 3 && (profile.zip2 || '').length === 4;
  };

  const fillAddress = async () => {
    const address = await getAddress(`${profile.zip1}${profile.zip2}`);
    update({ prefecture: address.region, address1: `${address.locality}${address.street}` });
  };

  const validate = () => {
    const validate = new Validator(profile);
    validate.exec('familyName', '姓', [Rule.require()]);
    validate.exec('firstName', '名', [Rule.require()]);
    validate.exec('familyNameKana', 'セイ', [Rule.require()]);
    validate.exec('firstNameKana', 'メイ', [Rule.require()]);
    validate.exec('zip1', '郵便番号1', [Rule.require()]);
    validate.exec('zip2', '郵便番号2', [Rule.require()]);
    validate.exec('prefecture', '都道府県', [Rule.require()]);
    validate.exec('address1', '市区町村番地', [Rule.require()]);
    validate.exec('gender', '性別', [Rule.require()]);
    validate.exec('birthDate', '生年月日', [Rule.require()]);
    validate.exec('mobileTel', 'TEL', [Rule.require()]);
    validate.exec('university', '大学名', [Rule.require()]);
    validate.exec('major', '学部', [Rule.require()]);
    validate.exec('course', '学科', [Rule.require()]);
    validate.exec('schoolYear', '学年', [Rule.require()]);
    validate.exec('examExperience', '受験経験', [Rule.require()]);
    validate.exec('commuteMethod', '通勤手段', [Rule.require()]);
    validate.exec('station', '自宅最寄り駅', [Rule.require()]);
    validate.exec('commutingTime', '片道通勤時間', [Rule.require()]);
    validate.exec('teachDays', '指導可能曜日', [Rule.require()]);
    validate.exec('teachableSubjects', '指導可能科目', [Rule.require()]);
    validate.exec('houseTeachExperience', '家庭教師の指導経験', [Rule.require()]);
    validate.exec('teachExperience', '指導経験', [Rule.require()]);
    validate.exec('pr', '自己PR', [Rule.require()]);
    validate.exec('profileFileId', '顔写真', [Rule.require()]);
    validate.exec('resumeFileId', '身分証', [Rule.require()]);
    setErrors(validate.getErrors());
    return validate.inValid();
  };

  const onSave = async () => {
    if (validate()) {
      toTopPos();
      Toast.error('入力エラーがあります。');
      return;
    }
    if (isLoading) {
      return;
    }
    await updateProfile({ teacher: profile.forApi() });
    toast('変更を保存しました。');
    history.push('/profile');
  };
  const onCancel = () => {
    history.push('/profile');
  };

  const update = (newProp = {}) => {
    setProfile(new Teacher({ ...profile, ...newProp }));
  };

  const onFileInputChange = (newFile, reference, attribute = '', setFile = () => {}) => {
    setLoading(true);
    if (newFile.target.files == null) return;
    const file = newFile.target.files[0];
    const reader = new FileReader();
    reader.onloadend = async () => {
      const dataURI = reader.result;
      const base64Data = dataURI.split(',')[1] || dataURI;
      const temporaryFile = new TemporaryFile({
        fileName: file.name,
        attribute: attribute,
        mime: file.type,
        data: base64Data,
      });
      const tempFile = await uploadTemporaryFile({ file: temporaryFile });
      const newTmpFiles = [...profile.tmpFiles, { attribute, id: tempFile.id }];

      const fileAttribute = setFile(dataURI, tempFile);
      const newTeacher = new Teacher({ ...profile, tmpFiles: newTmpFiles, ...fileAttribute });
      setProfile(newTeacher);
      setLoading(false);
    };
    reader.readAsDataURL(file);
    if (reference.current) reference.current.value = '';
  };

  const uploadProfile = (dataURI, tmpFile) => {
    setProfileImage(dataURI);
    return { profileFileId: tmpFile.id };
  };
  const uploadResume = (dataURI, tmpFile) => {
    setResumeImage(dataURI);
    return { resumeFileId: tmpFile.id };
  };

  const clickFileUpload = (reference) => {
    reference.current.click();
  };

  const removeProfileFile = () => {
    const newTmpFiles = _.filter([...profile.tmpFiles], (f) => f.attribute !== 'profile');
    const newTeacher = new Teacher({ ...profile, tmpFiles: newTmpFiles });
    setProfile(newTeacher);
    setProfileImage(null);
    setProfile(new Teacher({ ...profile, profileFileId: null }));
  };

  const removeResumeFile = () => {
    const newTmpFiles = _.filter([...profile.tmpFiles], (f) => f.attribute !== 'resume');
    const newTeacher = new Teacher({ ...profile, tmpFiles: newTmpFiles });
    setProfile(newTeacher);
    setResumeImage(null);
    setProfile(new Teacher({ ...profile, resumeFileId: null }));
  };

  return (
    <>
      <Layout>
        <Container fluid className={style.editWrapper}>
          <Form noValidate>
            {profile && (
              <>
                <Row className={style.header}>
                  <Col xs={2}></Col>
                  <Col xs={8} className={style.pageTitle}>
                    個人設定編集
                  </Col>
                  <Col xs={2}></Col>
                </Row>
                <FItemGroup label="氏名" separate full={false} errors={errors} paths={['familyName', 'firstName']}>
                  <Col xs={6}>
                    <FTxt
                      prefix="姓"
                      require={true}
                      value={profile.familyName}
                      change={(e) => update({ familyName: e.target.value })}
                    />
                  </Col>
                  <Col xs={6}>
                    <FTxt
                      prefix="名"
                      require={true}
                      value={profile.firstName}
                      change={(e) => update({ firstName: e.target.value })}
                    />
                  </Col>
                </FItemGroup>
                <FItemGroup
                  label="カナ"
                  separate
                  full={false}
                  errors={errors}
                  paths={['familyNameKana', 'firstNameKana']}
                >
                  <Col xs={6}>
                    <FTxt
                      prefix="セイ"
                      require={true}
                      value={profile.familyNameKana}
                      change={(e) => update({ familyNameKana: e.target.value })}
                    />
                  </Col>
                  <Col xs={6}>
                    <FTxt
                      prefix="メイ"
                      require={true}
                      value={profile.firstNameKana}
                      change={(e) => update({ firstNameKana: e.target.value })}
                    />
                  </Col>
                </FItemGroup>
                <FItemGroup label="郵便番号" separate full={false} errors={errors} paths={['zip1', 'zip2']}>
                  <Col xs={3} className={style.zip1Col}>
                    <FNum
                      value={profile.zip1}
                      require={true}
                      error=""
                      change={(e) => update({ zip1: e.target.value })}
                    />
                  </Col>
                  <Col xs={1} className={style.zipSeparateCol}>
                    －
                  </Col>
                  <Col xs={4} className={style.zip2Col}>
                    <FNum
                      value={profile.zip2}
                      require={true}
                      error=""
                      change={(e) => update({ zip2: e.target.value })}
                    />
                  </Col>
                  <Col xs={4} className={style.zip2Col}>
                    <Btn click={async () => await fillAddress()} disabled={!canFillAddress()}>
                      住所を入力
                    </Btn>
                  </Col>
                </FItemGroup>
                <FItemGroup label="住所" separate full={false} errors={errors} paths={['prefecture', 'address1']}>
                  <div className={style.subItemLabel}>都道府県</div>
                  <div>
                    <FSel
                      value={profile.prefecture}
                      error=""
                      require={true}
                      change={(e) => update({ prefecture: e.target.value })}
                      options={prefecture.map((r) => {
                        return { value: r, label: r };
                      })}
                    />
                  </div>
                  <div className={style.subItemLabel}>市区町村番地(ご家庭には市区町村までしか開示されません)</div>
                  <div>
                    <FTxt
                      value={profile.address1}
                      require={true}
                      error=""
                      change={(e) => update({ address1: e.target.value })}
                    />
                  </div>
                  <div className={style.subItemLabel}>アパートマンション</div>
                  <div>
                    <FTxt value={profile.address2} error="" change={(e) => update({ address2: e.target.value })} />
                  </div>
                </FItemGroup>
                <FItemGroup label="性別" separate errors={errors} paths={['gender']}>
                  <FSel
                    value={profile.gender}
                    error=""
                    require={true}
                    change={(e) => update({ gender: e.target.value })}
                    options={gender.map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label="生年月日" separate errors={errors} paths={['birthDate']}>
                  <FDate
                    value={profile.birthDate}
                    require={true}
                    error=""
                    change={(e) => update({ birthDate: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label="TEL" separate full={false} errors={errors} paths={['mobileTel']}>
                  <FTxt
                    value={profile.mobileTel}
                    require={true}
                    error=""
                    change={(e) => update({ mobileTel: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'メールアドレス'} separate>
                  {profile.mail}
                </FItemGroup>
                <FItemGroup
                  label={'大学名(家庭教師選定のための参考情報として使用します。)'}
                  separate
                  errors={errors}
                  paths={['university']}
                >
                  <FTxt
                    value={profile.university}
                    require={true}
                    error=""
                    change={(e) => update({ university: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'学部（研究科）'} separate errors={errors} paths={['major']}>
                  <FTxt
                    value={profile.major}
                    require={true}
                    error=""
                    change={(e) => update({ major: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'学科（専攻）'} separate errors={errors} paths={['course']}>
                  <FTxt
                    value={profile.course}
                    require={true}
                    error=""
                    change={(e) => update({ course: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup
                  label={'学年 (既卒の方はコメント欄に卒業年度をご記入下さい(例:平成15年卒))'}
                  separate
                  errors={errors}
                  paths={['schoolYear']}
                >
                  <SelectOrInput
                    value={profile.schoolYear}
                    otherValue={'卒業'}
                    require={true}
                    options={_.map(schoolYears, (sy) => {
                      return { label: sy, value: sy };
                    })}
                    change={(e) => update({ schoolYear: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'受験経験'} separate errors={errors} paths={['examExperience']}>
                  <FCheck
                    gName={'examExperience'}
                    value={profile.examExperience}
                    error=""
                    require={true}
                    change={(e) => update({ examExperience: e })}
                    options={['中学受験', '高校受験', '大学受験'].map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label={'通勤手段'} separate require={true} errors={errors} paths={['commuteMethod']}>
                  <FCheck
                    gName={'commuteMethod'}
                    value={profile.commuteMethod}
                    error=""
                    require={true}
                    change={(e) => update({ commuteMethod: e })}
                    options={['車', 'バイク', '公共交通機関', '自転車・徒歩'].map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label={'自宅最寄り駅'} separate errors={errors} paths={['station']}>
                  <FTxt
                    value={profile.station}
                    require={true}
                    error=""
                    change={(e) => update({ station: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup
                  label={'片道通勤時間(通勤にかけられる時間)'}
                  separate
                  errors={errors}
                  paths={['commutingTime']}
                >
                  <FTxt
                    value={profile.commutingTime}
                    error=""
                    require={true}
                    change={(e) => update({ commutingTime: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'指導可能曜日'} separate errors={errors} paths={['teachDays']}>
                  <FCheck
                    gName={'teachDays'}
                    value={profile.teachDays}
                    error=""
                    require={true}
                    change={(e) => update({ teachDays: e })}
                    options={['日', '月', '火', '水', '木', '金', '土'].map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label={'指導可能科目'} separate errors={errors} paths={['teachableSubjects']}>
                  <FCheck
                    gName={'teachableSubjects'}
                    value={profile.teachableSubjects}
                    error=""
                    require={true}
                    change={(e) => update({ teachableSubjects: e })}
                    options={[
                      '英会話',
                      '小学',
                      '中学',
                      '高1理系',
                      '高1文系',
                      '高2理系',
                      '高2文系',
                      '高3理系',
                      '高3文系',
                    ].map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup
                  label={'出身中学(※私立中学校に通われた方は必ず記入してください)'}
                  separate
                  errors={errors}
                  paths={[]}
                >
                  <FTxt
                    value={profile.juniorHighSchool}
                    error=""
                    change={(e) => update({ juniorHighSchool: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'出身高校'} separate errors={errors} paths={[]}>
                  <FTxt value={profile.highSchool} error="" change={(e) => update({ highSchool: e.target.value })} />
                </FItemGroup>
                <FItemGroup label={'家庭教師の指導経験'} separate errors={errors} paths={['houseTeachExperience']}>
                  <FTxt
                    value={profile.houseTeachExperience}
                    error=""
                    require={true}
                    change={(e) => update({ houseTeachExperience: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'指導経験'} separate errors={errors} paths={['teachExperience']}>
                  <FSel
                    value={profile.teachExperience}
                    error=""
                    require={true}
                    change={(e) => update({ teachExperience: e.target.value })}
                    options={['家庭教師', '塾講師', '両方', 'なし'].map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup
                  label={'コメント（自己PR・指導経験など）※指導を担当の際お客様にもみていただく項目となります。'}
                  separate
                  errors={errors}
                  paths={['pr']}
                >
                  <div>
                    【中学校・高校での通塾体験や受験を通して学んだこと】、【今まで指導経験】、【指導した生徒の合格実績】
                    <br />
                    【生徒を指導する上で心がけていること】【自己PR（趣味・特技・資格など）】についてできるだけ詳しく書いてください。
                    <br />
                    既卒の方はコメント欄に卒業年度をご記入下さい(例:令和2年卒)。
                  </div>
                  <FNote
                    value={profile.pr}
                    error=""
                    rows={20}
                    require={true}
                    change={(e) => update({ pr: e.target.value })}
                  />
                  <div>
                    ＜＜＜記入例＞＞＞
                    <br />
                    【中学校・高校での通塾体験や受験を通して学んだこと】
                    <br />
                    勉強は自分でやってはじめて勉強であると言うことを感じました。
                    <br />
                    宿題をやらされているという受け身の状態から、自分で学び取ることが大切と気持ちが切り替わってから成績が大きく伸びたと思っています。
                    <br />
                    受験で学んだことを活かして、勉強に対する姿勢や勉強の仕方などを楽しく教えたいと思っています。
                    <br />
                    【今まで指導経験】
                    <br />
                    今まで、家庭教師アルバイトとして中学生を2人、高校生を3人指導してきました。受験生は全て志望校に合格させることができました。
                    <br />
                    家庭教師以外に、個別指導塾にて高校生を中心に指導しています。
                    <br />
                    【指導した生徒の合格実績】
                    <br />
                    ○○高校、□□中学、××大学など
                    <br />
                    【生徒を指導する上で心がけていること】
                    <br />
                    楽しい授業をすることを心がけています。
                    <br />
                    勉強って面白いと思ってもらえるように色々な工夫をしながら指導をしています。
                    <br />
                    【自己PR（趣味・特技・資格など）】
                    <br />
                    趣味はスノーボードです。冬場には毎週のようにゲレンデに足を運びます。
                    <br />
                    スポーツ観戦も好きです。
                    <br />
                    高校までは部活でバスケットボールをやっていました。
                    <br />
                    大学卒業時には、小学校と中学校の教員免許を取得する予定です。
                  </div>
                </FItemGroup>

                <FItemGroup label={'顔写真'} separate errors={errors} paths={['profileFileId']}>
                  {profileImage && (
                    <>
                      <img className={style.image} alt={'顔写真'} src={profileImage} />
                      <Btn
                        isCustom
                        outline
                        prefixIcon="faXmark"
                        click={removeProfileFile}
                        width="30px"
                        height="30px"
                        className={style.xmarkButton}
                      />
                    </>
                  )}
                  {!profileImage && (
                    <>
                      <input
                        id="profileImage"
                        type="file"
                        hidden
                        ref={profileImageRef}
                        onChange={(e) => onFileInputChange(e, profileImageRef, 'profile', uploadProfile)}
                        accept="image/*"
                      ></input>
                      <Btn
                        outline
                        click={!isLoading ? () => clickFileUpload(profileImageRef) : null}
                        prefixIcon="faCamera"
                      >
                        {isLoading ? '画像処理中...' : '画像を選択'}
                      </Btn>
                    </>
                  )}
                </FItemGroup>
                <FItemGroup
                  label={'身分証（学生証・免許証・マイナンバーカード・保険証）'}
                  separate
                  errors={errors}
                  paths={['resumeFileId']}
                >
                  {resumeImage && (
                    <>
                      <img
                        className={style.image}
                        alt={'身分証（学生証・免許証・マイナンバーカード・保険証）'}
                        src={resumeImage}
                      />
                      <Btn
                        isCustom
                        outline
                        prefixIcon="faXmark"
                        click={removeResumeFile}
                        width="30px"
                        height="30px"
                        className={style.xmarkButton}
                      />
                    </>
                  )}
                  {!resumeImage && (
                    <>
                      <input
                        id="resumeFile"
                        type="file"
                        hidden
                        ref={resumeFileRef}
                        onChange={(e) => onFileInputChange(e, resumeFileRef, 'resume', uploadResume)}
                        accept="image/*"
                      ></input>
                      <Btn
                        outline
                        click={!isLoading ? () => clickFileUpload(resumeFileRef) : null}
                        prefixIcon="faCamera"
                      >
                        {isLoading ? '画像処理中...' : '画像を選択'}
                      </Btn>
                    </>
                  )}
                </FItemGroup>
                <Row className={style.footer}>
                  <Col>
                    <Btn prefixIcon="faFloppyDisk" click={async () => await onSave()} showLoading={true}>
                      {isLoading ? '画像処理中...' : '保存'}
                    </Btn>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '2rem' }}>
                  <Col>
                    <Btn prefixIcon="faXmark" outline color="secondary" click={() => onCancel()}>
                      キャンセル
                    </Btn>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Container>
      </Layout>
    </>
  );
};

export default GoodEdit;
