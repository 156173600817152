import React, { useEffect } from 'react';
import Layout from '@/layout/Layout';
import style from './CoachingIncome.scss';

const CoachingIncome = () => {
  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <>
      <Layout>
        指導料金
        <div className={style.guidanceMaterial}></div>
      </Layout>
    </>
  );
};

export default CoachingIncome;
