export const stringOrArray = (param) => {
  if (!param) {
    return [];
  }
  if (typeof param === 'string') {
    if (param === '') {
      return [];
    }
    try {
      return param.split(',');
    } catch (_) {
      return [param];
    }
  } else {
    return param;
  }
};
