import React from 'react';
import { Route, Switch } from 'react-router';
import Login from '@/pages/Login';
import Password from '@/pages/Password';
import Reset from '@/pages/Reset';
import VerifyReset from '@/pages/VefifyReset';
import GoodEdit from '@/pages/profile/good/GoodEdit';
import GoodProfile from '@/pages/profile/good/GoodProfile';
import GoalEdit from '@/pages/profile/goal/GoalEdit';
import GoalProfile from '@/pages/profile/goal/GoalProfile';
import Matter from '@/pages/matter/Matter';
import Students from '@/pages/student/Students';
import Karte from '@/pages/student/Karte';
import CoachingDocuments from '@/pages/coachingDocument/CoachingDocuments';
import Document from '@/pages/coachingDocument/CoachingMovie';
// import Report from '@/pages/report/Report';
import Report from '@/pages/report/Report';
import CoachingIncome from '@/pages/coachingIncome/CoachingIncome';
import { isGoal } from '@/util/company';
import EasyLogin from '@/pages/EasyLogin';

export const isNoNeedLogins = (location) => {
  return (
    ['/login', '/password', '/verifyReset', '/reset'].filter((l) => {
      return l === location;
    }).length > 0
  );
};

const profile = isGoal() ? GoalProfile : GoodProfile;
const editProfile = isGoal() ? GoalEdit : GoodEdit;

const routes = (
  <div>
    <Switch>
      <Route exact path="/" component={Students} />
      <Route path="/login" component={Login} />
      <Route path="/password" component={Password} />
      <Route path="/verifyReset/:resetToken" component={VerifyReset} />
      <Route path="/reset" component={Reset} />
      <Route path="/profile/edit" component={editProfile} />
      <Route path="/profile" component={profile} />
      <Route path="/matter" component={Matter} />
      {/* <Route path="/student/:id/report/:reportId" component={ReportEdit} /> */}
      <Route path="/student/:id/report" component={Report} />
      <Route path="/student/:id/karte" component={Karte} />
      <Route path="/student" component={Students} />
      <Route path="/coaching-document" component={CoachingDocuments} />
      <Route path="/coaching-income" component={CoachingIncome} />
      <Route path="/coaching-movie/:id" component={Document} />
      <Route exact path="/mo4eit8EahYo5eig/:token" component={EasyLogin} />
    </Switch>
  </div>
);

export default routes;
