import PropTypes from 'prop-types';
import Icon from '@/components/basic/Icon';
import { useEffect } from 'react';

const PasswordChecker = ({ password, setValid }) => {
  useEffect(() => {
    setValid(satisfyLength() && satisfyLargeLetter() && satisfyNumberLetter());
  }, [password]);

  const satisfyLength = () => {
    return password.length >= 8;
  };
  const satisfyLargeLetter = () => {
    return (password.match(/[A-Z]/) || []).length > 0;
  };
  const satisfyNumberLetter = () => {
    return (password.match(/[0-9]/) || []).length > 0;
  };

  return (
    <>
      <ul>
        <li style={{ listStyle: 'none' }}>
          <Icon iName={'faCircleCheck'} style={{ color: satisfyLength() ? 'green' : 'gray' }} />
          8文字以上
        </li>
        <li style={{ listStyle: 'none' }}>
          <Icon iName={'faCircleCheck'} style={{ color: satisfyLargeLetter() ? 'green' : 'gray' }} />
          英大文字を含む
        </li>
        <li style={{ listStyle: 'none' }}>
          <Icon iName={'faCircleCheck'} style={{ color: satisfyNumberLetter() ? 'green' : 'gray' }} />
          数字を含む
        </li>
      </ul>
    </>
  );
};
PasswordChecker.propTypes = {
  password: PropTypes.string,
  setValid: PropTypes.func,
};

PasswordChecker.defaultProps = {
  password: '',
  setValid: () => {},
};

export default PasswordChecker;
