import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { me } from '@/api';
import Layout from '@/layout/Layout';
import { Btn, Ic } from '@/components/basic/index';
import { DItemGroup } from '@/components/shared/index';
import style from '../Profile.scss';
import { getFileContent } from '@/api/file';

const GoalProfile = () => {
  const history = useHistory();
  const [profile, setProfile] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [resumeImage, setResumeImage] = useState(null);

  useEffect(() => {
    (async () => {
      const myProfile = await me();
      setProfile(myProfile);
      const profileFile = myProfile.profileFile();
      if (profileFile) {
        setProfileImage(await contentsOf({ id: profileFile.id }));
      }
      const resumeFile = myProfile.resumeFile();
      if (resumeFile) {
        setResumeImage(await contentsOf({ id: resumeFile.id }));
      }
    })();
  }, []);

  const onEdit = () => {
    history.push('/profile/edit');
  };

  const contentsOf = async ({ id }) => {
    const blob = await getFileContent({ id });
    return URL.createObjectURL(blob);
  };

  return (
    <>
      <Layout>
        <Container fluid className={style.wrapper}>
          {profile && (
            <>
              <Row className={style.header}>
                <Col xs={2}></Col>
                <Col xs={8} className={style.pageTitle}>
                  個人設定
                </Col>
                <Col xs={2}>
                  {!profile.restricted && (
                    <Btn
                      prefixIcon="faPenToSquare"
                      isCustom
                      width="36px"
                      height="36px"
                      click={onEdit}
                      circle
                      className={style.editBtn}
                    />
                  )}
                </Col>
              </Row>
              <DItemGroup label="氏名">{profile.fullName()}</DItemGroup>
              <DItemGroup label="カナ">{profile.fullNameKana()}</DItemGroup>
              <DItemGroup label="性別">{profile.gender}</DItemGroup>
              <DItemGroup label="生年月日">{profile.birthDate}</DItemGroup>
              <DItemGroup label="住所">
                {(profile.zip() !== '' ? '〒' : '') + profile.zip()}
                <br />
                {`${profile.prefecture} ${profile.address1} ${profile.address2}`}
              </DItemGroup>
              <DItemGroup label="TEL">
                {profile.mobileTel && (
                  <>
                    <div>
                      <span className={style.iconWrapper}>
                        <Ic iName="faMobileScreenButton" />
                      </span>
                      {profile.mobileTel}
                    </div>
                  </>
                )}
              </DItemGroup>
              <DItemGroup label={'メールアドレス'}>{profile.mail}</DItemGroup>
              <DItemGroup label={'大学名'}>{`${profile.university}${profile.major}${profile.schoolYear}`}</DItemGroup>
              <DItemGroup label={'出身高校'}>{profile.highSchool}</DItemGroup>
              <DItemGroup label={'アレルギー'}>{profile.allergy}</DItemGroup>
              <DItemGroup label={'指導経験'}>{profile.teachExperience}</DItemGroup>
              <DItemGroup label={'通勤手段'}>{profile.commuteMethod.join(',')}</DItemGroup>
              <DItemGroup label={'発達障害、学習障害の生徒の指導'}>{profile.attributes.handicapTeach}</DItemGroup>
              <DItemGroup label={'オンライン指導'}>{profile.attributes.onlineTeach}</DItemGroup>
              <DItemGroup label={'指導可能期間'}>{profile.attributes.teachLimit}</DItemGroup>
              <DItemGroup label={'入金希望銀行'}>{profile.bank}</DItemGroup>
              <DItemGroup label={'入金希望銀行番号'}>{profile.bankNumber}</DItemGroup>
              <DItemGroup label={'支店名（カタカナ）'}>{profile.bankBranch}</DItemGroup>
              <DItemGroup label={'支店番号（半角数字）'}>{profile.bankBranchNumber}</DItemGroup>
              <DItemGroup label={'口座番号（半角数字）'}>{profile.accountNumber}</DItemGroup>
              <DItemGroup label={'口座名義人（カタカナ）'}>{profile.accountName}</DItemGroup>
              <DItemGroup label={'得意科目'}>{profile.attributes.favoriteSubject}</DItemGroup>
              <DItemGroup label={'苦手科目'}>{profile.attributes.weakSubject}</DItemGroup>

              <DItemGroup label={'勤務可能地域'}>{profile.attributes.workArea}</DItemGroup>
              <DItemGroup label={'指導可能学年'}>{(profile.attributes.teachGrade || []).join(',')}</DItemGroup>
              <DItemGroup label={'指導可能科目'}>{profile.attributes.teachSubject}</DItemGroup>
              <DItemGroup label={'指導可能な受験'}>{(profile.attributes.teachExam || []).join(',')}</DItemGroup>
              <DItemGroup label={'HPの先生ページ掲載'}>{profile.attributes.publishHP}</DItemGroup>
              {'掲載可能' === profile.attributes.publishHP && (
                <DItemGroup label={'HPの先生ページ掲載時ニックネーム'}>{profile.attributes.publishNickName}</DItemGroup>
              )}
              <DItemGroup label={'家庭教師を始めようと思ったきっかけ'}>{profile.attributes.workReason}</DItemGroup>
              <DItemGroup label={'帰省先'}>
                {(profile.zipFamily() !== '' ? '〒' : '') + profile.zipFamily()}
                <br />
                {`${profile.prefectureFamily} ${profile.address1Family} ${profile.address2Family} `}
              </DItemGroup>
              <DItemGroup label={'帰省先続柄'} separate></DItemGroup>

              <DItemGroup label={'現在の職業'}>{profile.attributes.profession}</DItemGroup>
              <DItemGroup label={'職歴'}>{profile.attributes.workHistory}</DItemGroup>
              <DItemGroup label={'婚姻'}>{profile.attributes.marriage}</DItemGroup>
              <DItemGroup label={'お子様の有無'}>{profile.attributes.hasChild}</DItemGroup>
              <DItemGroup label={'過去の部活動や活動履歴'}>{profile.attributes.workShop}</DItemGroup>
              <DItemGroup label={'受験経験'}>{(profile.examExperience || []).join(',')}</DItemGroup>
              <DItemGroup label={'趣味や好きなこと'}>{profile.attributes.hobby}</DItemGroup>
              <DItemGroup label={'特技'}>{profile.attributes.specialSkil}</DItemGroup>

              <DItemGroup label={'顔写真'}>
                {profileImage && <img className={style.image} alt={'顔写真'} src={profileImage} />}
              </DItemGroup>
              <DItemGroup label={'身分証明書'}>
                {resumeImage && <img className={style.image} alt={'身分証明書'} src={resumeImage} />}
              </DItemGroup>
            </>
          )}
        </Container>
      </Layout>
    </>
  );
};

export default GoalProfile;
