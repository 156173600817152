import _ from 'lodash';
import { File } from './File';
import { stringOrArray } from '@/util/string';
import { strToArray, strToJson } from '@/util/util';

class Teacher {
  constructor(param = {}) {
    this.id = param.id || null;
    this.familyName = param.familyName || '';
    this.firstName = param.firstName || '';
    this.familyNameKana = param.familyNameKana || '';
    this.firstNameKana = param.firstNameKana || '';
    this.gender = param.gender || '';
    this.birthDate = param.birthDate;
    this.age = param.age;
    this.zip1 = param.zip1 || '';
    this.zip2 = param.zip2 || '';
    this.prefecture = param.prefecture || '';
    this.address1 = param.address1 || '';
    this.address2 = param.address2 || '';
    this.tel = param.tel || '';
    this.mail = param.mail || '';
    this.mobileTel = param.mobileTel || '';
    this.university = param.university || '';
    this.major = param.major || '';
    this.course = param.course || '';
    this.schoolYear = param.schoolYear || '';
    this.station = param.station || '';
    this.highSchool = param.highSchool || '';
    this.juniorHighSchool = param.juniorHighSchool || '';
    this.zip1Family = param.zip1Family || '';
    this.zip2Family = param.zip2Family || '';
    this.prefectureFamily = param.prefectureFamily || '';
    this.address1Family = param.address1Family || '';
    this.address2Family = param.address2Family || '';
    this.telFamily = param.telFamily || '';
    this.examExperience = strToArray(param.examExperience);
    this.teachExperience = param.teachExperience || '';
    this.houseTeachExperience = param.houseTeachExperience || '';
    this.allergy = param.allergy || '';
    this.teachDays = strToArray(param.teachDays);
    this.teachableSubjects = strToArray(param.teachableSubjects);
    this.commuteMethod = stringOrArray(param.commuteMethod);
    this.commutingTime = param.commutingTime || '';
    this.pr = param.pr || '';

    this.bank = param.bank || '';
    this.bankNumber = param.bankNumber || '';
    this.bankBranch = param.bankBranch || '';
    this.bankBranchNumber = param.bankBranchNumber || '';
    this.accountType = param.accountType || '';
    this.accountNumber = param.accountNumber || '';
    this.accountName = param.accountName || '';
    this.attributes = new ProfileAttribute(strToJson(param.attributes));

    this.teacherFiles = (param.teacherFiles || []).map((f) => {
      return new File({
        id: f.fileId,
        attribute: f.attribute,
      });
    });
    this.tmpFiles = param.tmpFiles || [];
    this.restricted = param.restricted || false;
    this.profileFileId = param.profileFileId;
    this.resumeFileId = param.resumeFileId;
  }

  fullName() {
    return `${this.familyName} ${this.firstName}`;
  }

  fullNameKana() {
    return `${this.familyNameKana} ${this.firstNameKana}`;
  }

  zip() {
    return this.zip1 === '' ? '' : `${this.zip1}-${this.zip2}`;
  }

  fullAddress() {
    return `${this.zip()} ${this.prefecture} ${this.address1} ${this.address2}`;
  }

  zipFamily() {
    return this.zip1Family === '' ? '' : `${this.zip1Family}-${this.zip2Family}`;
  }

  fullAddressFamily() {
    return `${this.zipFamily()} ${this.prefectureFamily} ${this.address1Family} ${this.address2Family}`;
  }

  profileFile() {
    return _.find(this.teacherFiles, (f) => {
      return f.attribute === 'profile';
    });
  }

  resumeFile() {
    return _.find(this.teacherFiles, (f) => {
      return f.attribute === 'resume';
    });
  }

  forApi() {
    return {
      familyName: this.familyName,
      firstName: this.firstName,
      familyNameKana: this.familyNameKana,
      firstNameKana: this.firstNameKana,
      gender: this.gender,
      birthDate: this.birthDate,
      grade: this.grade,
      age: this.age,
      zip1: this.zip1,
      zip2: this.zip2,
      prefecture: this.prefecture,
      address1: this.address1,
      address2: this.address2,
      tel: this.tel,
      mobileTel: this.mobileTel,
      university: this.university,
      major: this.major,
      course: this.course,
      schoolYear: this.schoolYear,
      station: this.station,
      highSchool: this.highSchool,
      juniorHighSchool: this.juniorHighSchool,
      zip1Family: this.zip1Family,
      zip2Family: this.zip2Family,
      prefectureFamily: this.prefectureFamily,
      address1Family: this.address1Family,
      address2Family: this.address2Family,
      telFamily: this.telFamily,
      examExperience: this.examExperience.join(','),
      teachExperience: this.teachExperience,
      houseTeachExperience: this.houseTeachExperience,
      allergy: this.allergy,
      teachDays: (this.teachDays || []).join(','),
      teachableSubjects: (this.teachableSubjects || []).join(','),
      tmpFileIds: this.tmpFiles.map((f) => {
        return f.id;
      }),
      commuteMethod: this.commuteMethod.join(','),
      commutingTime: this.commutingTime,
      pr: this.pr,
      bank: this.bank,
      bankNumber: this.bankNumber,
      bankBranch: this.bankBranch,
      bankBranchNumber: this.bankBranchNumber,
      accountType: this.accountType,
      accountNumber: this.accountNumber,
      accountName: this.accountName,

      attributes: this.attributes.forApi(),
    };
  }
}

class ProfileAttribute {
  constructor(param = {}) {
    // 発達障害・学習障害の生徒の指導
    this.handicapTeach = param.handicapTeach || '';
    // オンラインでの指導
    this.onlineTeach = param.onlineTeach || '';
    // 指導可能期間
    this.teachLimit = param.teachLimit || '';
    // 得意科目
    this.favoriteSubject = param.favoriteSubject || '';
    // 苦手科目
    this.weakSubject = param.weakSubject || '';
    // 勤務可能地域
    this.workArea = param.workArea || '';
    // 指導可能学年
    this.teachGrade = param.teachGrade || [];
    // 指導可能科目
    this.teachSubject = param.teachSubject || '';
    // 指導可能な受験
    this.teachExam = param.teachExam || [];
    // HPの先生ページ掲載
    this.publishHP = param.publishHP || '';
    // HPの先生ページ掲載時ニックネーム
    this.publishNickName = param.publishNickName || '';
    // 家庭教師を始めようと思ったきっかけ
    this.workReason = param.workReason || '';
    // 現在の職業
    this.profession = param.profession || '';
    // 職歴
    this.workHistory = param.workHistory || '';
    // 婚姻
    this.marriage = param.marriage || '';
    // お子様の有無
    this.hasChild = param.hasChild || '';
    // 過去の部活動や活動履歴
    this.workShop = param.workShop || '';
    // 趣味や好きなこと
    this.hobby = param.hobby || '';
    // 特技
    this.specialSkil = param.specialSkil || '';
  }

  forApi() {
    return JSON.stringify({ ...this });
  }
}

export default Teacher;
