import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card } from 'react-bootstrap';
import dayjs from 'dayjs';
import { getSettlementAllIds, getSettlement, getUnsettledReports, addSettlement, paymented } from '@/api/index';
import { FNum, Btn, Ic } from '@/components/basic/index';
import { ErrMsg, Toast } from '@/components/shared/index';
import style from '@/pages/report/Report.scss';
import { Settlement } from '@/entity/Report';
import { Validator, Rule } from '@/util/validate';
import { isGoal } from '@/util/company';

const Settlements = ({ familyId }) => {
  const [isAddMode, setIsAddMode] = useState(false);
  const [settlement, setSettlement] = useState(new Settlement());
  const [allIds, setAllIds] = useState([]);
  const [page, setPage] = useState([0, 0]);
  const [errors, setErrors] = useState({});

  const handleNew = async () => {
    const md = new Settlement();
    const data = await getUnsettledReports(familyId);
    md.setUnsettledReports(data);
    if (data.length === 0) {
      Toast.warn('未精算の指導報告はありません。');
      return;
    }
    setSettlement(md);
    setIsAddMode(true);
  };
  const handleCancel = async () => {
    const ids = await getAllIds(familyId);
    if (ids.length !== 0) {
      setSettlement(await getSettlement(familyId, ids[ids.length - 1].id));
    }
    setErrors({});
    setIsAddMode(false);
  };
  const handlePrev = async () => {
    const n = page[0] - 1;
    if (n < 1) return;
    setPage([n, page[1]]);
    setErrors({});
    setSettlement(await getSettlement(familyId, allIds[n - 1].id));
  };
  const handleNext = async () => {
    const n = page[0] + 1;
    if (n > page[1]) return;
    setPage([n, page[1]]);
    setErrors({});
    setSettlement(await getSettlement(familyId, allIds[n - 1].id));
  };
  const validate = () => {
    const validate = new Validator(settlement);
    validate.exec('fee', '精算金額', [Rule.require(), Rule.num(), Rule.numRange(0, 999999)]);
    setErrors(validate.getErrors());
    return validate.inValid();
  };
  const canNotEdit = () => {
    return isGoal() || settlement.isPaymented;
  };
  // なんか100ミリ秒遅らさないとページトップにいかない。
  const toTopPos = () => setTimeout(() => window.scrollTo(0, 0), 100);
  const handleSubmit = async () => {
    if (validate()) {
      toTopPos();
      Toast.error('入力エラーがあります。');
      return;
    }
    settlement.date = new Date();
    const day = dayjs(new Date());
    settlement.date = day.isValid() ? day.format('YYYY-MM-DD') : '';
    // TODO: 入力チェックを入れる
    try {
      await addSettlement(familyId, settlement);
      Toast.success('精算の依頼を追加しました');
      setIsAddMode(false);
      const ids = await getAllIds(familyId);
      if (ids.length !== 0) {
        setSettlement(await getSettlement(familyId, ids[ids.length - 1].id));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const handlePaymented = async () => {
    if (validate()) {
      toTopPos();
      Toast.error('入力エラーがあります。');
      return;
    }
    try {
      await paymented(familyId, settlement.id, settlement);
      Toast.success('精算済に更新しました');
      setIsAddMode(false);
      const ids = await getAllIds(familyId);
      if (ids.length !== 0) {
        setSettlement(await getSettlement(familyId, ids[ids.length - 1].id));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const getAllIds = async () => {
    const ids = await getSettlementAllIds(familyId);
    setAllIds(ids);
    setPage([ids.length, ids.length]);
    return ids;
  };
  useEffect(async () => {
    const ids = await getAllIds(familyId);
    if (ids.length !== 0) {
      setSettlement(await getSettlement(familyId, ids[ids.length - 1].id));
    }
  }, []);
  return (
    <Container fluid>
      {isAddMode ? (
        <></>
      ) : (
        <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <Col>
            <Btn children="新しい精算" click={handleNew} color="success" prefixIcon="faPlus" />
          </Col>
        </Row>
      )}
      {allIds.length !== 0 && !isAddMode ? (
        <Card className={style.header}>
          <Row>
            <Col xs={3} style={{ textAlign: 'right' }}>
              <Btn isCustom className={style.pagerBtn} outline border={false} circle click={handlePrev}>
                <Ic iName="faAnglesLeft" />
              </Btn>
            </Col>
            <Col xs={6}>
              <Row className={style.title}>
                <Col>
                  {' '}
                  {page[0]}回 / {page[1]}回{' '}
                </Col>
              </Row>
            </Col>
            <Col xs={3} style={{ textAlign: 'left' }}>
              <Btn isCustom className={style.pagerBtn} outline border={false} circle click={handleNext}>
                <Ic iName="faAnglesRight" />
              </Btn>
            </Col>
          </Row>
        </Card>
      ) : (
        <></>
      )}
      {settlement.reports.length !== 0 ? (
        <div style={{ marginTop: '1rem' }}>
          <table className={style.closingTable}>
            <thead>
              <tr>
                <th>指導日</th>
                <th>指導料</th>
                <th>交通費</th>
              </tr>
            </thead>
            {settlement.reports.map((r, i) => {
              return (
                <tbody key={i + 'closing-n'}>
                  <tr>
                    <td>{r.date}</td>
                    <td>{r.fee}</td>
                    <td>{r.travelCost}</td>
                  </tr>
                </tbody>
              );
            })}
            <tbody>
              <tr>
                <td>小計</td>
                <td>{settlement.sumFee}</td>
                <td>{settlement.sumTravelCost}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td colSpan="2">合計</td>
                <td>{settlement.grandTotal}</td>
              </tr>
            </tbody>
          </table>
          <Row>
            <Col
              xs={{ span: 4, offset: 3 }}
              style={{ textAlign: 'right', fontSize: '15px', marginTop: '1.5rem', color: 'rgb(122, 122, 122)' }}
            >
              精算金額
            </Col>
            <Col xs={5} style={{ marginTop: '1rem' }}>
              <FNum
                value={settlement.fee}
                className={style.totalAmount}
                disabled={canNotEdit()}
                change={(e) => setSettlement({ ...settlement, fee: e.target.value })}
              />
            </Col>
          </Row>
          {errors.fee && (
            <Row>
              <Col style={{ textAlign: 'right' }}>
                <ErrMsg iconStyle={{ marginRight: '0.25rem' }} message={errors.fee} />
              </Col>
            </Row>
          )}
        </div>
      ) : (
        <></>
      )}
      {settlement.isPaymented || !settlement.id ? (
        <></>
      ) : (
        <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <Col>
            <Btn children="精算済にする" color="primary" click={handlePaymented} showLoading={true} />
          </Col>
        </Row>
      )}
      {isAddMode ? (
        <>
          <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Col>
              <Btn children="登録する" color="primary" click={handleSubmit} showLoading={true} />
            </Col>
          </Row>
          <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <Col>
              <Btn children="キャンセル" color="secondary" outline click={handleCancel} />
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

Settlements.propTypes = {
  familyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
export default Settlements;
