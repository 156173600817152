import PropTypes from 'prop-types';
import { Form, InputGroup } from 'react-bootstrap';
import basicStyle from './Basic.scss';

const FormText = ({
  size,
  disabled,
  className,
  style,
  value,
  prefix,
  suffix,
  error,
  change,
  placeholder,
  require = false,
}) => {
  const classNames = () => {
    const requireClass = `${value}` === '' && require;
    return [basicStyle.glText, className, requireClass ? basicStyle.requireInput : ''];
  };
  const inValid = error !== null && error !== undefined && error !== '';
  return (
    <InputGroup size={size}>
      {prefix ? <InputGroup.Text>{prefix}</InputGroup.Text> : <></>}
      <Form.Control
        type="text"
        className={classNames().filter(Boolean).join(' ')}
        disabled={disabled}
        value={value}
        size={size}
        style={style}
        onChange={change}
        isInvalid={inValid}
        placeholder={placeholder}
      />
      {suffix ? <InputGroup.Text>{suffix}</InputGroup.Text> : <></>}
      {inValid ? <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback> : <></>}
    </InputGroup>
  );
};

FormText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  change: PropTypes.func,
  prefix: PropTypes.string,
  suffix: PropTypes.node,
  error: PropTypes.string,
};

FormText.defaultProps = {
  size: 'sm',
  disabled: false,
  change: () => {},
  error: null,
};

export default FormText;
