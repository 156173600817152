import React, { useEffect, useState } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import { getCoachingDocumentTextbook, getCoachingDocuments } from '@/api/index';
import CoachingDocument from '@/entity/CoachingDocument';
import Layout from '@/layout/Layout';
import { Ic } from '@/components/basic/index';
import { toHms } from '@/util/util';
import style from './CoachingDocuments.scss';

const CoachingDocuments = () => {
  const [coachingDocuments, setCoachingDocuments] = useState([]);
  const [directory, setDirectory] = useState([]);

  const sortFunction = (l, r) => ((l.name || '') > (r.name || '') ? 1 : -1);

  const getSetDocuments = async (id) => {
    const res = await getCoachingDocuments({ id });
    setCoachingDocuments(
      res
        .map((r) => {
          return new CoachingDocument(r);
        })
        .sort(sortFunction)
    );
  };

  const handleNext = async (param) => {
    await getSetDocuments(param.id);
    setDirectory([...directory, param].sort(sortFunction));
  };

  const openWindow = (row) => {
    if (row.type === 'movie') {
      window.open('/coaching-movie/' + row.id);
      return;
    }
    const windowTarget = window.open();
    (async () => {
      const res = await getCoachingDocumentTextbook({ id: row.id });
      windowTarget.location = URL.createObjectURL(new Blob([res], { type: row.mime }));
    })();
  };

  const isTopDisabled = () => {
    return directory.length === 0;
  };
  const isOtherDisabled = (index) => {
    return directory.length === index + 1;
  };

  const returnFolderTop = async () => {
    await getSetDocuments('');
    setDirectory([]);
  };

  const returnFolder = async (param, index) => {
    await getSetDocuments(param.id);
    const newBreadcrumbs = directory.filter((r, i) => index >= i).sort(sortFunction);
    setDirectory(newBreadcrumbs);
  };

  useEffect(() => {
    (async () => {
      await getSetDocuments('');
    })();
  }, []);

  return (
    <>
      <Layout>
        指導資料
        <div className={style.guidanceMaterial}>
          <div className={style.breadcrumbs}>
            <div className={style.breadcrumb}>
              <ul>
                <li>
                  {isTopDisabled() ? (
                    'TOP'
                  ) : (
                    <div>
                      <a className={style.breadcrumbLink} onClick={() => returnFolderTop()}>
                        TOP
                      </a>
                    </div>
                  )}
                </li>
                {directory.map((r, i) => {
                  return (
                    <li key={i + 'breadcrumbs'}>
                      {isOtherDisabled(i) ? (
                        <a>
                          {'>'}
                          {r.name}
                        </a>
                      ) : (
                        <div>
                          {'>'}
                          <a className={style.breadcrumbLink} onClick={() => returnFolder(r, i)}>
                            {r.name}
                          </a>
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <ListGroup>
            {coachingDocuments.map((r, i) => {
              return (
                <React.Fragment key={i + 'file'}>
                  <ListGroup.Item action onClick={() => (r.type === 'directory' ? handleNext(r) : openWindow(r))}>
                    <Row>
                      <Col>
                        <span className={style.pathIconWrapper}>
                          <Ic iName={r.frontIconName} className={style.fileIcon} />
                        </span>
                        <span>{r.name}</span>
                      </Col>
                    </Row>
                    {r.type === 'movie' && r.teacherVideoViewingHistory.length !== 0 ? (
                      <Row>
                        <Col style={{ textAlign: 'right' }}>
                          {r.teacherVideoViewingHistory[0].isWatchTillEnd ? (
                            <>
                              <span className={style.completed}>
                                <Ic iName="faCheck" />
                              </span>
                              <span>視聴完了</span>
                            </>
                          ) : (
                            <>視聴時間: {toHms(r.teacherVideoViewingHistory[0].viewingTime)}</>
                          )}
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                  </ListGroup.Item>
                </React.Fragment>
              );
            })}
          </ListGroup>
        </div>
      </Layout>
    </>
  );
};

export default CoachingDocuments;
