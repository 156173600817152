export const eventMgr = (() => {
  const events = {};
  return {
    addListener: (target, type, listener, capture, key) => {
      if (window.addEventListener) {
        target.addEventListener(type, listener, capture);
      } else if (window.attachEvent) {
        target.attachEvent('on' + type, listener);
      }
      events[key] = {
        target: target,
        type: type,
        listener: listener,
        capture: capture,
      };
    },
    removeListener: (key) => {
      if (key in events) {
        var e = events[key];
        if (window.removeEventListener) {
          e.target.removeEventListener(e.type, e.listener, e.capture);
        } else if (window.detachEvent) {
          e.target.detachEvent('on' + e.type, e.listener);
        }
      }
    },
  };
})();

export const isFullScreen = () => {
  if (
    (document.fullscreenElement !== undefined && document.fullscreenElement !== null) ||
    (document.mozFullScreenElement !== undefined && document.mozFullScreenElement !== null) ||
    (document.webkitFullscreenElement !== undefined && document.webkitFullscreenElement !== null) ||
    (document.webkitCurrentFullScreenElement !== undefined && document.webkitCurrentFullScreenElement !== null) ||
    (document.msFullscreenElement !== undefined && document.msFullscreenElement !== null)
  ) {
    return true;
  } else {
    return false;
  }
};

export const toHms = (val) => {
  const t = Math.ceil(val);
  let hms = '';
  let h = (t / 3600) | 0;
  let m = ((t % 3600) / 60) | 0;
  let s = t % 60;
  if (h !== 0) {
    hms = h + ':' + padZero(m) + ':' + padZero(s);
  } else if (m !== 0) {
    hms = m + ':' + padZero(s);
  } else {
    hms = '0:' + padZero(s);
  }
  return hms;
  function padZero(v) {
    if (v < 10) {
      return '0' + v;
    } else {
      return v;
    }
  }
};

export const isEmpty = (v) => v === null || v === undefined || v === '' || (v || []).length === 0;

export const isNotEmpty = (v) => !isEmpty(v);

export const strToArray = (v, separator = ',') => {
  if (isEmpty(v)) {
    return [];
  }
  if (typeof v === 'string') {
    return v.split(separator);
  }
  return v;
};

export const strToJson = (v = '{}') => {
  if (typeof v === 'string') {
    return JSON.parse(v);
  }
  return v;
};
