import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import toast from 'react-hot-toast';
import { me, updateProfile } from '@/api';
import Layout from '@/layout/Layout';
import Teacher from '@/entity/Teacher';
import { gender, onlineTeach, handicapTeach, prefecture, schoolYears } from '@/util/constants';
import { Btn, FDate, FNum, FSel, FTxt, Ic, FRadio, FCheck, FRadioAndDetail } from '@/components/basic/index';
import { FItemGroup } from '@/components/shared/index';
import style from '../Profile.scss';
import TemporaryFile from '@/entity/File';
import { getFileContent, uploadTemporaryFile } from '@/api/file';
import { getAddress } from '@/util/address';

const GoalEdit = () => {
  const history = useHistory();
  const [profile, setProfile] = useState(null);
  const [profileFile, setProfileFile] = useState(new TemporaryFile({}));
  const [resumeFile, setResumeFile] = useState(new TemporaryFile({}));
  const [isLoading, setLoading] = useState(false);
  const profileImageRef = useRef(null);
  const resumeFileRef = useRef(null);
  const [profileImage, setProfileImage] = useState(null);
  const [resumeImage, setResumeImage] = useState(null);

  useEffect(() => {
    (async () => {
      const myProfile = await me();
      setProfile(myProfile);
      const profileFile = myProfile.profileFile();
      if (profileFile) {
        setProfileImage(await contentsOf({ id: profileFile.id }));
      }
      const resumeFile = myProfile.resumeFile();
      if (resumeFile) {
        setResumeImage(await contentsOf({ id: resumeFile.id }));
      }
    })();
  }, []);

  const contentsOf = async ({ id }) => {
    const blob = await getFileContent({ id });
    return URL.createObjectURL(blob);
  };

  const canFillAddress = ({ zip1, zip2 }) => {
    return (zip1 || '').length === 3 && (zip2 || '').length === 4;
  };

  const fillAddress = async ({ zip1, zip2, prefectureProp, address1Prop }) => {
    const address = await getAddress(`${zip1}${zip2}`);
    const updateAttr = {};
    updateAttr[prefectureProp] = address.region;
    updateAttr[address1Prop] = `${address.locality}${address.street}`;
    update(updateAttr);
  };

  const canNotSave = () => {
    // エンティティでやりたいけど、ゴールとグッドであまりにもモデルが違うからここでやる
    const profileRequiresInput =
      [
        profile.familyName,
        profile.firstName,
        profile.familyNameKana,
        profile.firstNameKana,
        profile.birthDate,
        profile.zip1,
        profile.zip2,
        profile.prefecture,
        profile.address1,
        profile.address2,
        profile.mobileTel,
        profile.university,
        profile.major,
        profile.schoolYear,
        profile.stage,
        profile.highSchool,
        profile.zip1Family,
        profile.zip2Family,
        profile.prefectureFamily,
        profile.address1Family,
        profile.address2Family,
        profile.telFamily,
        ...profile.examExperience,
        profile.teachExperience,
        profile.allergy,
        profile.teachDays,
        ...profile.commuteMethod,
        profile.bank,
        profile.bankNumber,
        profile.bankBranch,
        profile.bankBranchNumber,
        profile.accountNumber,
        profile.accountName,
      ].filter((i) => {
        return i === '';
      }).length > 0;

    console.log(profileRequiresInput);

    const attributeInput =
      [
        profile.attributes.handicapTeach,
        profile.attributes.onlineTeach,
        profile.attributes.teachLimit,
        profile.attributes.favoriteSubject,
        profile.attributes.weakSubject,
        profile.attributes.workArea,
        ...profile.attributes.teachGrade,
        profile.attributes.teachSubject,
        ...profile.attributes.teachExam,
        profile.attributes.publishHP,
        profile.attributes.publishNickName,
        profile.attributes.workReason,
      ].filter((i) => {
        return i === '';
      }).length > 0;

    return profileRequiresInput || attributeInput;
  };

  const onSave = async () => {
    if (isLoading) {
      return;
    }
    await updateProfile({ teacher: profile.forApi() });
    toast('変更を保存しました。');
    history.push('/profile');
  };

  const onCancel = () => {
    history.push('/profile');
  };

  const update = (newProp = {}) => {
    setProfile(new Teacher({ ...profile.forApi(), ...newProp }));
  };
  const updateAttribute = (newProp = {}) => {
    const newAttribute = { ...profile.attributes, ...newProp };
    setProfile(new Teacher({ ...profile.forApi(), attributes: JSON.stringify(newAttribute) }));
  };

  const onFileInputChange = (newFile, reference, attribute = '', setFile = () => {}) => {
    setLoading(true);
    if (newFile.target.files == null) return;
    const reader = new FileReader();
    const file = newFile.target.files[0];
    reader.readAsDataURL(file);
    reader.onloadend = async () => {
      const dataURI = reader.result;
      const base64Data = dataURI.split(',')[1] || dataURI;
      const temporaryFile = new TemporaryFile({
        fileName: file.name,
        attribute: attribute,
        mime: file.type,
        data: base64Data,
      });
      setFile(temporaryFile);
      const tempFile = await uploadTemporaryFile({ file: temporaryFile });
      const newTeacher = new Teacher({ ...profile, tmpFiles: [...profile.tmpFiles, tempFile] });
      setProfile(newTeacher);
      setLoading(false);
    };
    if (reference.current) reference.current.value = '';
  };

  const clickFileUpload = (reference) => {
    reference.current.click();
  };

  return (
    <>
      <Layout>
        <Container fluid className={style.editWrapper}>
          <Form noValidate>
            {profile && (
              <>
                <Row className={style.header}>
                  <Col xs={2}></Col>
                  <Col xs={8} className={style.pageTitle}>
                    個人設定編集
                  </Col>
                  <Col xs={2}></Col>
                </Row>
                <FItemGroup label="氏名" separate full={false} require={true}>
                  <Col xs={6}>
                    <FTxt
                      prefix="姓"
                      error=""
                      value={profile.familyName}
                      change={(e) => update({ familyName: e.target.value })}
                    />
                  </Col>
                  <Col xs={6}>
                    <FTxt
                      prefix="名"
                      error=""
                      value={profile.firstName}
                      change={(e) => update({ firstName: e.target.value })}
                    />
                  </Col>
                </FItemGroup>
                <FItemGroup label="カナ" separate full={false} require={true}>
                  <Col xs={6}>
                    <FTxt
                      prefix="セイ"
                      error=""
                      value={profile.familyNameKana}
                      change={(e) => update({ familyNameKana: e.target.value })}
                    />
                  </Col>
                  <Col xs={6}>
                    <FTxt
                      prefix="メイ"
                      error=""
                      value={profile.firstNameKana}
                      change={(e) => update({ firstNameKana: e.target.value })}
                    />
                  </Col>
                </FItemGroup>
                <FItemGroup label="郵便番号" separate full={false} require={true}>
                  <Col xs={3} className={style.zip1Col}>
                    <FNum value={profile.zip1} error="" change={(e) => update({ zip1: e.target.value })} />
                  </Col>
                  <Col xs={1} className={style.zipSeparateCol}>
                    －
                  </Col>
                  <Col xs={4} className={style.zip2Col}>
                    <FNum value={profile.zip2} error="" change={(e) => update({ zip2: e.target.value })} />
                  </Col>
                  <Col xs={4} className={style.zip2Col}>
                    <Btn
                      click={async () =>
                        await fillAddress({
                          zip1: profile.zip1,
                          zip2: profile.zip2,
                          prefectureProp: 'prefecture',
                          address1Prop: 'address1',
                        })
                      }
                      disabled={!canFillAddress({ zip1: profile.zip1, zip2: profile.zip2 })}
                    >
                      住所を入力
                    </Btn>
                  </Col>
                </FItemGroup>
                <FItemGroup label="住所" separate full={false} require={true}>
                  <div className={style.subItemLabel}>都道府県</div>
                  <div>
                    <FSel
                      value={profile.prefecture}
                      error=""
                      change={(e) => update({ prefecture: e.target.value })}
                      options={prefecture.map((r) => {
                        return { value: r, label: r };
                      })}
                    />
                  </div>
                  <div className={style.subItemLabel}>市区町村番地</div>
                  <div>
                    <FTxt value={profile.address1} error="" change={(e) => update({ address1: e.target.value })} />
                  </div>
                  <div className={style.subItemLabel}>アパートマンション</div>
                  <div>
                    <FTxt value={profile.address2} error="" change={(e) => update({ address2: e.target.value })} />
                  </div>
                </FItemGroup>
                <FItemGroup label="性別" separate require={true}>
                  <FSel
                    value={profile.gender}
                    error=""
                    change={(e) => update({ gender: e.target.value })}
                    options={gender.map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label="生年月日" separate require={true}>
                  <FDate value={profile.birthDate} error="" change={(e) => update({ birthDate: e.target.value })} />
                </FItemGroup>
                <FItemGroup label="TEL" separate full={false} require={true}>
                  <Row style={{ marginRight: 0, paddingRight: 0, marginTop: '0.5rem' }}>
                    <Col xs={1} style={{ marginTop: '0.3rem', textAlign: 'center', paddingRight: 0 }}>
                      <Ic iName="faMobileScreenButton" />
                    </Col>
                    <Col xs={11} style={{ marginRight: 0, paddingRight: 0 }}>
                      <FTxt value={profile.mobileTel} error="" change={(e) => update({ mobileTel: e.target.value })} />
                    </Col>
                  </Row>
                </FItemGroup>
                <FItemGroup label={'メールアドレス'} separate>
                  {profile.mail}
                </FItemGroup>
                <FItemGroup
                  label={'大学名'}
                  separate
                  require={true}
                  detail={'高卒や専門卒の方は高校・専門学校名を記入してください。'}
                >
                  <FTxt value={profile.university} error="" change={(e) => update({ university: e.target.value })} />
                </FItemGroup>
                <FItemGroup label={'学部'} separate require={true}>
                  <FTxt value={profile.major} error="" change={(e) => update({ major: e.target.value })} />
                </FItemGroup>
                <FItemGroup label={'学年'} separate require={true}>
                  <FSel
                    value={profile.schoolYear}
                    error=""
                    change={(e) => update({ schoolYear: e.target.value })}
                    options={schoolYears.map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label={'出身高校'} separate require={true}>
                  <FTxt value={profile.highSchool} error="" change={(e) => update({ highSchool: e.target.value })} />
                </FItemGroup>

                <FItemGroup label={'アレルギー'} separate require={true}>
                  <FRadioAndDetail
                    gName={'allergy'}
                    value={profile.allergy}
                    detailOption={'あり'}
                    error=""
                    change={(e) => update({ allergy: e })}
                    options={['あり', 'なし'].map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label={'指導経験'} separate require={true}>
                  <FRadioAndDetail
                    gName={'teachExperience'}
                    value={profile.teachExperience}
                    detailOption={'その他'}
                    error=""
                    change={(e) => update({ teachExperience: e })}
                    options={['なし', '家庭教師', '塾講師', 'その他'].map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label={'通勤手段'} separate require={true}>
                  <FCheck
                    gName={'commuteMethod'}
                    value={profile.commuteMethod}
                    error=""
                    change={(e) => update({ commuteMethod: e })}
                    options={['車', 'バイク', '公共交通機関', '自転車・徒歩'].map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label={'発達障害、学習障害の生徒の指導'} separate require={true}>
                  <FRadio
                    gName={'handicapTeach'}
                    value={profile.attributes.handicapTeach}
                    error=""
                    change={(e) => updateAttribute({ handicapTeach: e.target.value })}
                    options={handicapTeach.map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label={'オンライン指導'} separate require={true}>
                  <FRadio
                    gName={'onlineTeach'}
                    value={profile.attributes.onlineTeach}
                    error=""
                    change={(e) => updateAttribute({ onlineTeach: e.target.value })}
                    options={onlineTeach.map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label={'指導可能期間'} separate require={true}>
                  <FTxt
                    value={profile.attributes.teachLimit}
                    error=""
                    change={(e) => updateAttribute({ teachLimit: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'入金希望銀行名'} separate require={true}>
                  <FTxt value={profile.bank} error="" change={(e) => update({ bank: e.target.value })} />
                </FItemGroup>
                <FItemGroup label={'入金希望銀行コード'} separate require={true}>
                  <FTxt value={profile.bankNumber} error="" change={(e) => update({ bankNumber: e.target.value })} />
                </FItemGroup>
                <FItemGroup label={'支店名（カタカナ）'} separate require={true}>
                  <FTxt value={profile.bankBranch} error="" change={(e) => update({ bankBranch: e.target.value })} />
                </FItemGroup>
                <FItemGroup label={'支店番号（半角数字）'} separate require={true}>
                  <FTxt
                    value={profile.bankBranchNumber}
                    error=""
                    change={(e) => update({ bankBranchNumber: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'口座番号（半角数字）'} separate require={true}>
                  <FTxt
                    value={profile.accountNumber}
                    error=""
                    change={(e) => update({ accountNumber: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'口座名義人（カタカナ）'} separate require={true}>
                  <FTxt value={profile.accountName} error="" change={(e) => update({ accountName: e.target.value })} />
                </FItemGroup>
                <FItemGroup label={'得意科目'} separate require={true}>
                  <FTxt
                    value={profile.attributes.favoriteSubject}
                    error=""
                    change={(e) => updateAttribute({ favoriteSubject: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'苦手科目'} separate require={true}>
                  <FTxt
                    value={profile.attributes.weakSubject}
                    error=""
                    change={(e) => updateAttribute({ weakSubject: e.target.value })}
                  />
                </FItemGroup>

                <FItemGroup label={'勤務可能地域'} separate require={true}>
                  <FTxt
                    value={profile.attributes.workArea}
                    error=""
                    change={(e) => updateAttribute({ workArea: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'指導可能学年'} separate require={true}>
                  <FCheck
                    gName={'examExperience'}
                    value={profile.attributes.teachGrade}
                    error=""
                    change={(e) => updateAttribute({ teachGrade: e })}
                    options={['幼児', '小学生', '中学生', '高校生', '大学生'].map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label={'指導可能科目'} separate require={true}>
                  <FTxt
                    value={profile.attributes.teachSubject}
                    error=""
                    change={(e) => updateAttribute({ teachSubject: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'指導可能な受験'} separate require={true}>
                  <FCheck
                    gName={'examExperience'}
                    value={profile.attributes.teachExam}
                    error=""
                    change={(e) => updateAttribute({ teachExam: e })}
                    options={['中学受験', '高校受験', '大学受験'].map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label={'HPの先生ページ掲載'} separate require={true}>
                  <FRadio
                    gName={'publishHP'}
                    value={profile.attributes.publishHP}
                    error=""
                    change={(e) => updateAttribute({ publishHP: e.target.value })}
                    options={['掲載可能', '掲載不可'].map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                {'掲載可能' === profile.attributes.publishHP && (
                  <>
                    <FItemGroup
                      label={'HPの先生ページ掲載時ニックネーム'}
                      separate
                      detail={'「〇〇先生」と表示されます。（空欄の場合はイニシャルで表示）'}
                      require={true}
                    >
                      <FTxt
                        value={profile.attributes.publishNickName}
                        error=""
                        change={(e) => updateAttribute({ publishNickName: e.target.value })}
                      />
                    </FItemGroup>
                  </>
                )}
                <FItemGroup
                  label={'家庭教師を始めようと思ったきっかけ'}
                  separate
                  require={true}
                  detail={'紹介の場合は紹介者のお名前をフルネームで記入してください。'}
                >
                  <FTxt
                    value={profile.attributes.workReason}
                    error=""
                    change={(e) => updateAttribute({ workReason: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'緊急連絡先郵便番号'} separate full={false} require={true}>
                  <Col xs={3} className={style.zip1Col}>
                    <FNum value={profile.zip1Family} error="" change={(e) => update({ zip1Family: e.target.value })} />
                  </Col>
                  <Col xs={1} className={style.zipSeparateCol}>
                    －
                  </Col>
                  <Col xs={4} className={style.zip2Col}>
                    <FNum value={profile.zip2Family} error="" change={(e) => update({ zip2Family: e.target.value })} />
                  </Col>
                  <Col xs={4} className={style.zip2Col}>
                    <Btn
                      click={async () =>
                        await fillAddress({
                          zip1: profile.zip1Family,
                          zip2: profile.zip2Family,
                          prefectureProp: 'prefectureFamily',
                          address1Prop: 'address1Family',
                        })
                      }
                      disabled={!canFillAddress({ zip1: profile.zip1Family, zip2: profile.zip2Family })}
                    >
                      住所を入力
                    </Btn>
                  </Col>
                </FItemGroup>
                <FItemGroup label={'緊急連絡先住所'} separate full={false} require={true}>
                  <div className={style.subItemLabel}>都道府県</div>
                  <div>
                    <FSel
                      value={profile.prefectureFamily}
                      error=""
                      change={(e) => update({ prefectureFamily: e.target.value })}
                      options={prefecture.map((r) => {
                        return { value: r, label: r };
                      })}
                    />
                  </div>
                  <div className={style.subItemLabel}>市区町村番地</div>
                  <div>
                    <FTxt
                      value={profile.address1Family}
                      error=""
                      change={(e) => update({ address1Family: e.target.value })}
                    />
                  </div>
                  <div className={style.subItemLabel}>アパートマンション</div>
                  <div>
                    <FTxt
                      value={profile.address2Family}
                      error=""
                      change={(e) => update({ address2Family: e.target.value })}
                    />
                  </div>
                </FItemGroup>
                <FItemGroup label={'緊急連絡先電話番号'} separate require={true}>
                  <FTxt value={profile.telFamily} error="" change={(e) => update({ telFamily: e.target.value })} />
                </FItemGroup>

                <FItemGroup label={'現在の職業'} separate>
                  <FTxt
                    value={profile.attributes.profession}
                    error=""
                    change={(e) => updateAttribute({ profession: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'職歴'} separate>
                  <FTxt
                    value={profile.attributes.workHistory}
                    error=""
                    change={(e) => updateAttribute({ workHistory: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'婚姻'} separate>
                  <FRadio
                    gName={'marriage'}
                    value={profile.attributes.marriage}
                    error=""
                    change={(e) => updateAttribute({ marriage: e.target.value })}
                    options={['未婚', '既婚'].map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label={'お子様の有無'} separate>
                  <FTxt
                    value={profile.attributes.hasChild}
                    error=""
                    change={(e) => updateAttribute({ hasChild: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'過去の部活動や活動履歴'} separate>
                  <FTxt
                    value={profile.attributes.workShop}
                    error=""
                    change={(e) => updateAttribute({ workShop: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'受験経験'} separate>
                  <FCheck
                    gName={'examExperience'}
                    value={profile.examExperience}
                    error=""
                    change={(e) => update({ examExperience: e })}
                    options={['中学受験', '高校受験', '大学受験'].map((r) => {
                      return { value: r, label: r };
                    })}
                  />
                </FItemGroup>
                <FItemGroup label={'趣味や好きなこと'} separate>
                  <FTxt
                    value={profile.attributes.hobby}
                    error=""
                    change={(e) => updateAttribute({ hobby: e.target.value })}
                  />
                </FItemGroup>
                <FItemGroup label={'特技'} separate>
                  <FTxt
                    value={profile.attributes.specialSkil}
                    error=""
                    change={(e) => updateAttribute({ specialSkil: e.target.value })}
                  />
                </FItemGroup>

                <FItemGroup label={'顔写真'} separate require={true}>
                  {profileImage && <img className={style.image} alt={'顔写真'} src={profileImage} />}
                  {!profileImage && profileFile.data === '' && (
                    <>
                      <input
                        id="profileImage"
                        type="file"
                        hidden
                        ref={profileImageRef}
                        onChange={(e) => onFileInputChange(e, profileImageRef, 'profile', setProfileFile)}
                        accept="image/*"
                      ></input>
                      <Btn
                        outline
                        click={!isLoading ? () => clickFileUpload(profileImageRef) : null}
                        prefixIcon="faCamera"
                      >
                        {isLoading ? '画像処理中...' : '画像を選択'}
                      </Btn>
                    </>
                  )}
                  {!profileImage && profileFile.data && (
                    <>
                      <img className={style.image} alt={'顔写真'} src={profileFile.dataForIMG()} />
                    </>
                  )}
                </FItemGroup>
                <FItemGroup label={'身分証明書'} separate require={true}>
                  {resumeImage && <img className={style.image} alt={'身分証明書'} src={resumeImage} />}
                  {!resumeImage && resumeFile.data === '' && (
                    <>
                      <input
                        id="resumeFile"
                        type="file"
                        hidden
                        ref={resumeFileRef}
                        onChange={(e) => onFileInputChange(e, resumeFileRef, 'resume', setResumeFile)}
                        accept="image/*"
                      ></input>
                      <Btn
                        outline
                        click={!isLoading ? () => clickFileUpload(resumeFileRef) : null}
                        prefixIcon="faCamera"
                      >
                        {isLoading ? '画像処理中...' : '画像を選択'}
                      </Btn>
                    </>
                  )}
                  {!resumeImage && resumeFile.data && (
                    <>
                      <img className={style.image} alt={'身分証明書'} src={resumeFile.dataForIMG()} />
                    </>
                  )}
                </FItemGroup>
                <Row className={style.footer}>
                  <Col>
                    <Btn
                      prefixIcon="faFloppyDisk"
                      click={async () => await onSave()}
                      showLoading={true}
                      disabled={canNotSave()}
                    >
                      {isLoading ? '画像処理中...' : '保存'}
                    </Btn>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '2rem' }}>
                  <Col>
                    <Btn prefixIcon="faXmark" outline color="secondary" click={() => onCancel()}>
                      キャンセル
                    </Btn>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Container>
      </Layout>
    </>
  );
};

export default GoalEdit;
