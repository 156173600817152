import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { me } from '@/api';
import Layout from '@/layout/Layout';
import { Btn } from '@/components/basic';
import { DItemGroup } from '@/components/shared';
import style from '../Profile.scss';
import { getFileContent } from '@/api/file';

const GoodProfile = () => {
  const history = useHistory();
  const [profile, setProfile] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [resumeImage, setResumeImage] = useState(null);

  useEffect(() => {
    (async () => {
      const myProfile = await me();
      setProfile(myProfile);
      const profileFile = myProfile.profileFile();
      if (profileFile) {
        setProfileImage(await contentsOf({ id: profileFile.id }));
      }
      const resumeFile = myProfile.resumeFile();
      if (resumeFile) {
        setResumeImage(await contentsOf({ id: resumeFile.id }));
      }
    })();
  }, []);

  const onEdit = () => {
    history.push('/profile/edit');
  };

  const contentsOf = async ({ id }) => {
    const blob = await getFileContent({ id });
    return URL.createObjectURL(blob);
  };

  return (
    <>
      <Layout>
        <Container fluid className={style.wrapper}>
          {profile && (
            <>
              <Row className={style.header}>
                <Col xs={2}></Col>
                <Col xs={8} className={style.pageTitle}>
                  個人設定
                </Col>
                <Col xs={2}>
                  <Btn
                    prefixIcon="faPenToSquare"
                    isCustom
                    width="36px"
                    height="36px"
                    click={onEdit}
                    circle
                    className={style.editBtn}
                  />
                </Col>
              </Row>
              <DItemGroup label="氏名">{profile.fullName()}</DItemGroup>
              <DItemGroup label="カナ">{profile.fullNameKana()}</DItemGroup>
              <DItemGroup label="性別">{profile.gender}</DItemGroup>
              <DItemGroup label="生年月日">{profile.birthDate}</DItemGroup>
              <DItemGroup label="住所">
                {(profile.zip() !== '' ? '〒' : '') + profile.zip()}
                <br />
                {`${profile.prefecture} ${profile.address1} ${profile.address2}`}
              </DItemGroup>
              <DItemGroup label={'TEL'}>{profile.mobileTel}</DItemGroup>
              <DItemGroup label={'メールアドレス'}>{profile.mail}</DItemGroup>
              <DItemGroup label={'大学名'}>
                {[profile.university, profile.major, profile.course, profile.schoolYear].join('　')}
              </DItemGroup>
              <DItemGroup label={'受験経験'}>{profile.examExperience.join(',')}</DItemGroup>
              <DItemGroup label={'交通手段'}>{profile.commuteMethod.join(',')}</DItemGroup>
              <DItemGroup label={'自宅最寄り駅'}>{profile.station}</DItemGroup>
              <DItemGroup label={'片道通勤時間'}>{profile.commutingTime}</DItemGroup>
              <DItemGroup label={'指導可能曜日'}>{profile.teachDays.join(',')}</DItemGroup>
              <DItemGroup label={'指導可能科目'}>{profile.teachableSubjects.join(',')}</DItemGroup>
              <DItemGroup label={'出身中学'}>{profile.juniorHighSchool}</DItemGroup>
              <DItemGroup label={'出身高校'}>{profile.highSchool}</DItemGroup>
              <DItemGroup label={'家庭教師の指導経験'}>{profile.houseTeachExperience}</DItemGroup>
              <DItemGroup label={'指導経験'}>{profile.teachExperience}</DItemGroup>
              <DItemGroup label={'自己PR'}>
                <pre>{profile.pr}</pre>
              </DItemGroup>
              <DItemGroup label={'顔写真'}>
                {profileImage && <img className={style.image} alt={'顔写真'} src={profileImage} />}
              </DItemGroup>
              <DItemGroup label={'身分証'}>
                {resumeImage && <img className={style.image} alt={'身分証'} src={resumeImage} />}
              </DItemGroup>
            </>
          )}
        </Container>
      </Layout>
    </>
  );
};

export default GoodProfile;
